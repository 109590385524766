export const landscapeData = {
  size: {
    portrait: 0.8,
    landscape: 0.9
  },
  segments: [
  {
    "point": {
      "x": 201.13685589534117,
      "y": 284.8329593874459
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": 6.834604651162806,
      "y": -16.38697674418603
    }
  },
  {
    "point": {
      "x": 243.5316465930156,
      "y": 259.04840124791104
    },
    "handleIn": {
      "x": -17.982511627906973,
      "y": 0.42865116279068616
    },
    "handleOut": {
      "x": 33.78902325581393,
      "y": -1.1043720930233007
    }
  },
  {
    "point": {
      "x": 306.17722798836445,
      "y": 316.3417965967482
    },
    "handleIn": {
      "x": -6.906046511627949,
      "y": -29.722790697674384
    },
    "handleOut": {
      "x": 14.639627906976727,
      "y": 62.993860465116256
    }
  },
  {
    "point": {
      "x": 292.53183263952724,
      "y": 503.3110989223296
    },
    "handleIn": {
      "x": -15.604093023255814,
      "y": -62.52353488372091
    },
    "handleOut": {
      "x": 31.571348837209314,
      "y": 101.7867906976744
    }
  },
  {
    "point": {
      "x": 421.5588093837133,
      "y": 451.32226171302733
    },
    "handleIn": {
      "x": 19.52148837209296,
      "y": 106.08818604651168
    },
    "handleOut": {
      "x": -7.617488372093078,
      "y": -47.91367441860467
    }
  },
  {
    "point": {
      "x": 380.51248380231794,
      "y": 312.90663380605054
    },
    "handleIn": {
      "x": 6.078511627906948,
      "y": 48.449488372093015
    },
    "handleOut": {
      "x": -14.40148837209307,
      "y": -102.70660465116276
    }
  },
  {
    "point": {
      "x": 557.5900651976667,
      "y": 220.89249427116684
    },
    "handleIn": {
      "x": -74.80260465116277,
      "y": -71.85265116279069
    },
    "handleOut": {
      "x": 36.05432558139546,
      "y": 36.741953488372104
    }
  },
  {
    "point": {
      "x": 581.0438326395272,
      "y": 363.66607566651567
    },
    "handleIn": {
      "x": 14.446139534883741,
      "y": -49.140093023255815
    },
    "handleOut": {
      "x": -17.937860465116273,
      "y": 59.65097674418604
    }
  },
  {
    "point": {
      "x": 500.97834426743424,
      "y": 530.4322152013995
    },
    "handleIn": {
      "x": 22.73934883720932,
      "y": -57.64167441860468
    },
    "handleOut": {
      "x": -30.940279069767485,
      "y": 74.2786976744186
    }
  },
  {
    "point": {
      "x": 562.3588093837133,
      "y": 675.7866338060505
    },
    "handleIn": {
      "x": -64.79776744186046,
      "y": -2.8130232558139596
    },
    "handleOut": {
      "x": 57.95720930232562,
      "y": 1.5211162790697017
    }
  },
  {
    "point": {
      "x": 671.527925662783,
      "y": 579.3937035734925
    },
    "handleIn": {
      "x": -14.112744186046484,
      "y": 50.65525581395332
    },
    "handleOut": {
      "x": 13.70493023255824,
      "y": -48.7531162790699
    }
  },
  {
    "point": {
      "x": 714.2620651976667,
      "y": 432.31277334093426
    },
    "handleIn": {
      "x": -34.354604651162845,
      "y": 40.96297674418611
    },
    "handleOut": {
      "x": 29.401302325581582,
      "y": -37.37004651162789
    }
  },
  {
    "point": {
      "x": 817.9271814767367,
      "y": 446.76486636419014
    },
    "handleIn": {
      "x": -9.600000000000136,
      "y": -50.753488372093045
    },
    "handleOut": {
      "x": 7.620465116278865,
      "y": 40.35274418604649
    }
  },
  {
    "point": {
      "x": 798.6676465930157,
      "y": 561.5362152013995
    },
    "handleIn": {
      "x": -1.9378604651162732,
      "y": -39.495441860465235
    },
    "handleOut": {
      "x": 2.283162790697588,
      "y": 48.12799999999993
    }
  },
  {
    "point": {
      "x": 870.5173210116204,
      "y": 597.9447733409343
    },
    "handleIn": {
      "x": -25.75776744186055,
      "y": 24.08781395348842
    },
    "handleOut": {
      "x": 23.540093023255622,
      "y": -25.519627906976666
    }
  },
  {
    "point": {
      "x": 879.3731349651086,
      "y": 495.69361055023666
    },
    "handleIn": {
      "x": 6.909023255813963,
      "y": 34.2772093023255
    },
    "handleOut": {
      "x": -5.965395348837092,
      "y": -29.606697674418626
    }
  },
  {
    "point": {
      "x": 860.0362047325505,
      "y": 407.1801221781436
    },
    "handleIn": {
      "x": 4.101953488372146,
      "y": 29.981767441860427
    },
    "handleOut": {
      "x": -4.128744186046333,
      "y": -30.151441860465184
    }
  },
  {
    "point": {
      "x": 863.4177861278993,
      "y": 315.66607566651567
    },
    "handleIn": {
      "x": -6.909023255813736,
      "y": 29.690046511627884
    },
    "handleOut": {
      "x": 34.75646511627929,
      "y": -136.44799999999998
    }
  },
  {
    "point": {
      "x": 1063.3359256627832,
      "y": 305.4528663641901
    },
    "handleIn": {
      "x": -13.636465116279169,
      "y": -104.64744186046508
    },
    "handleOut": {
      "x": 3.0690232558138177,
      "y": 28.180837209302354
    }
  },
  {
    "point": {
      "x": 1042.724948918597,
      "y": 383.5120291548878
    },
    "handleIn": {
      "x": 12.898232558139625,
      "y": -24.358697674418636
    },
    "handleOut": {
      "x": -30.020465116278956,
      "y": 56.69804651162792
    }
  },
  {
    "point": {
      "x": 996.897972174411,
      "y": 569.0286803176784
    },
    "handleIn": {
      "x": -2.301023255814016,
      "y": -64.53283720930227
    },
    "handleOut": {
      "x": 1.33060465116273,
      "y": 37.32837209302329
    }
  },
  {
    "point": {
      "x": 1021.5781582209227,
      "y": 677.3315640386088
    },
    "handleIn": {
      "x": -14.583069767441884,
      "y": -34.336744186046644
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  }
]
}