import React from 'react';
import {connect} from 'react-redux'

import './index.scss';

function LinkWrapper(props) {
  return (
    <span className={`linkwrapper ${props.className}`}
      onMouseEnter={() => {
        props.dispatch({type: 'SET_CURSOR_ACTIVE', active: true})

        if (props.cursorText) {
          props.dispatch({type: 'SET_CURSOR_TEXT', text: props.cursorText});
        }
        if (props.cursorTextSize) {
          props.dispatch({type: 'SET_CURSOR_TEXT_SIZE', textSize: props.cursorTextSize});
        }
      }} 
      onMouseLeave={() => {
        props.dispatch({type: 'SET_CURSOR_ACTIVE', active: false});
        props.dispatch({type: 'SET_CURSOR_TEXT', text: ''});
      }}
    >
    	{props.children}
    </span>
  );
}

export default connect()(LinkWrapper);
