import React, {useState, useEffect, useRef } from 'react';
import {connect} from 'react-redux'
import {Route, useRouteMatch} from 'react-router-dom'

import { fetchPeopleFromFilter, CloseFilter } from './../../actions'
import ArchiveFilter from '../ArchiveFilter';
import ActiveFilterTags from '../ActiveFilterTags';

// import BottomPageLink from './../BottomPageLink'
import PeopleSingle from './../PeopleSingle'
import Thumb from './Thumb'
import './index.scss';


import Footer from './../Footer'

function People(props) {

    const hasMountTermsGet = useRef(false);
    const hasMountTermsSet = useRef(false);

    const [currentQuery, setCurrentQuery] = useState();

    const singlePeopleMatch = useRouteMatch("/people/:id");

    // Close filter if entering by link, keep filter state if entering by browser history
    useEffect(() => {
        if (props.routerAction === 'PUSH') CloseFilter();
    }, [props.routerAction]);


    // update redux based on browserbar query
    useEffect(() => {
    
        let shouldUpdateQuery;
        
        if (hasMountTermsGet.current) {
            shouldUpdateQuery = true;
        } else {
            hasMountTermsGet.current = true;
            shouldUpdateQuery = false;
        }

        if ( JSON.stringify(currentQuery) === JSON.stringify(props.query) ) {
            shouldUpdateQuery = false;
        } else if (hasMountTermsGet.current) {
            shouldUpdateQuery = true;
            setCurrentQuery(props.query);
        }
        
        
        if (props.pathname === '/people') {
            props.dispatch({
                type: 'SET_ACTIVE_PEOPLE_TAGS',
                terms: props.query,
                shouldUpdateQuery: shouldUpdateQuery
            })
        }

    }, [props.query])

    
    // fetch from API whens selected terms changes in redux
    useEffect(() => {
        if (hasMountTermsSet.current) {
            if (
                props.selectedTerms &&
                props.shouldUpdateQuery
            ) {
                fetchPeopleFromFilter(props.selectedTerms, props.dataKey);
            }
        } else {
            hasMountTermsSet.current = true;
        }
    }, [props.dataKey, props.selectedTerms, props.shouldUpdateQuery]);

    
    useEffect(() => {
        if (singlePeopleMatch && singlePeopleMatch.isExact) {

            props.dispatch({
                type: 'SET_SCROLL_ON_OFF',
                val: false
            });

            props.dispatch({type: 'CLOSE_FILTER'});

            props.dispatch({type: 'SET_SINGLE_PERSON', slug: singlePeopleMatch.params.id});

        }
    }, [singlePeopleMatch]);

    const [peopleThumbs, setPeopleThumbs] = useState();

    useEffect(() => {
        setPeopleThumbs(Thumbs());
    }, [props.apiData.people]);
    

    const Thumbs = () => {
        const numOfPeopleInRow = 3;
        const numOfPeople = props.apiData.people.length;
        const numOfRows = Math.ceil(numOfPeople/numOfPeopleInRow)
        // let numOfThumbs = numOfPeople + Math.ceil(numOfPeople/7);
        // let numOfThumbs = numOfPeople + Math.ceil((numOfPeople + Math.floor(numOfPeople/13))/7) + Math.floor(numOfPeople/13);
        let peopleCounter = 0;
        let thumbs = []
        let positionOfEmptyThumbNextRow, positionOfEmptyThumb;

        for (let i = 0; i < numOfRows; i++) {
            while (positionOfEmptyThumb === positionOfEmptyThumbNextRow) {
                positionOfEmptyThumbNextRow = Math.floor(Math.random()*4);
            }

            positionOfEmptyThumb = positionOfEmptyThumbNextRow;

            for (let p = 0; p < numOfPeopleInRow+1 && peopleCounter < numOfPeople; p++) {
                let thumb;
                if (p === positionOfEmptyThumb) thumb = <div key={Math.random()} className="people__item people__item--empty"/>
                else {
                    thumb = <Thumb key={Math.random()} data={props.apiData.people[peopleCounter]} keepAspectRatio={true} />
                    peopleCounter++;
                }
                thumbs.push(thumb)
            }
        }
        return thumbs;
    }
    
/*
    const ThumbsAlt = () => {
        const numOfPeople = props.apiData.people.length;
        // let numOfThumbs = numOfPeople + Math.ceil(numOfPeople/7);
        let numOfThumbs = numOfPeople + Math.ceil((numOfPeople + Math.floor(numOfPeople/13))/7) + Math.floor(numOfPeople/13);
        let peopleCounter = 0;
        let thumbs = []

        for (let i = 0; i < numOfThumbs; i++) {
            // let showBlank = i%8 == 0
            let showBlank = i%8 == 0 || (i+1)%16 == 0
            let thumb = showBlank ? <div key={i} className="col col4 col8-s"/> : <Thumb key={i} data={props.apiData.people[peopleCounter]}/>
            // console.log(i, showBlank, peopleCounter);
            thumbs.push(thumb)
            if (!showBlank) peopleCounter++;
        }
        return thumbs;
    }
*/

    // console.log('PEOPLE', window.location.search);

    return (
        <div className="page people normal">

            <Route path={`/people/:id`} children={({match, ...rest}) => {
                return <PeopleSingle dataKey={props.dataKey} query={props.query} />
            }}/>

            <div className="page-section headline">
                <div className="l-columns-container--outmost">
                    <div className="people__header">
                        <h1 className="people__header__text f-section-headline">{props.apiData.headertext}</h1>
                    </div>
                </div>
            </div>

            <div className="page-section">
                <div className="people__tags l-columns-container--outmost">
                    <ActiveFilterTags parent="people" taxonomyAll={props.apiData.taxonomies} />
                </div>
                
                <div className="people__list l-columns-container l-columns-container--outmost">
                    {peopleThumbs}
                </div>
            </div>

            <ArchiveFilter parent="people" dataKey={props.dataKey} />

            {/* <BottomPageLink data={props.apiData.pagelink_bottom}/> */}
            <Footer/>

        </div>
    );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data,
    dataKey: ownProps.dataKey,
    selectedTerms: state.People.selectedTerms,
    query: state.router.location.query,
    pathname: state.router.location.pathname,
    scrollPosition: state.App.scrollPosition,
    routerAction: state.router.action,
    shouldUpdateQuery: state.People.shouldUpdateQuery,
    filterIsOpen: state.Sidebar.filterIsOpen,
}))(People)
