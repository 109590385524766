import {rodeoData} from './lassoDataRodeo'
import {socialScienceData} from './lassoDataSocialScience.js'
import {landscapeData} from './lassoDataLandscape.js'
import {architectureData} from './lassoDataArchitecture.js'
import {studioData} from './lassoDataStudio.js'
import {urbanismData} from './lassoDataUrbanism.js'

export var lassoDataObject = {
			'landscape': landscapeData,
			'architecture': architectureData,
			'urbanism': urbanismData,
			'studio': studioData,
			'social': socialScienceData,
			'rodeo': rodeoData
		}