import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux'

import './index.scss';

import { fetchArchiveFromFilter, CloseFilter } from './../../actions'
import ArchiveList from './ArchiveList'
import ActiveFilterTags from './../ActiveFilterTags'
import Footer from './../Footer'
import LinkWrapper from './../LinkWrapper'
import ArchiveFilter from '../ArchiveFilter';

function Archive(props) {
    const hasMountTermsGet = useRef(false)
    const hasMountTermsSet = useRef(false)

    const [layoutToggled, setLayoutToggled] = useState(false);

    // Close filter if entering by link, keep filter state if entering by browser history
    useEffect(() => {
        if (props.routerAction === 'PUSH') CloseFilter();
    }, [props.routerAction])

    // update redux based on browserbar query
    useEffect(() => {
        let shouldUpdateQuery;

        if (hasMountTermsGet.current) {
            shouldUpdateQuery = true;
        } else {
            hasMountTermsGet.current = true;
            shouldUpdateQuery = false;
        }

        if (props.pathname === '/projects') {
            props.dispatch({
                type: 'SET_ACTIVE_TAGS',
                terms: props.query,
                shouldUpdateQuery: shouldUpdateQuery
            })
        }

    }, [props, props.query])

    // fetch from API whens selected terms changes in redux
    useEffect(() => {
        if (hasMountTermsSet.current) {
            if (props.selectedTerms && props.shouldUpdateQuery) {
                fetchArchiveFromFilter(props.selectedTerms, props.dataKey);
            }
        } else {
            hasMountTermsSet.current = true;
        }
    }, [props.dataKey, props.selectedTerms, props.shouldUpdateQuery]);

    let layoutToggleIcon = (
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentColor">
                <rect width="7" height="7" transform="matrix(-9.93477e-08 1 1 9.93477e-08 12 0)" />
                <rect width="7" height="7" transform="matrix(-9.93477e-08 1 1 9.93477e-08 12 12)" />
                <rect width="7" height="7" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 7 0)" />
                <rect width="7" height="7" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 7 12)" />
            </g>
        </svg>
    );
    if (layoutToggled) {
        layoutToggleIcon = (
            <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g fill="currentColor">
                    <rect width="5" height="7" transform="matrix(-9.93477e-08 1 1 9.93477e-08 12 0)" />
                    <rect width="8" height="7" transform="matrix(-9.93477e-08 1 1 9.93477e-08 12 11)" />
                    <rect width="7" height="8" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 7 0)" />
                    <rect width="7" height="5" transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 7 14)" />
                </g>
            </svg>
        );
    }

    return (
        <div className="page project-archive">

            <div className="project-archive__tags l-columns-container--outmost">
                <ActiveFilterTags parent="projects" taxonomyAll={props.apiData.taxonomies} />
            </div>

            <ArchiveList projects={props.apiData.posts[0]} layoutSimpleGrid={layoutToggled} />

            <LinkWrapper>
                <div
                    className={`side-btn toggle-layout-btn ${layoutToggled ? ' open' : ''}`}
                    style={{ transform: `translateZ(0) translateY(0px)` }}
                    onClick={() => {
                        setLayoutToggled(!layoutToggled)}}
                >
                    {layoutToggleIcon}
                </div>
            </LinkWrapper>

            <ArchiveFilter parent="projects" dataKey={props.dataKey} />

            {/* <BottomPageLink data={props.apiData.pagelink_bottom[0]}/> */}
            <Footer />

        </div>
    );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data,
    dataKey: ownProps.dataKey,
    selectedTerms: state.Archive.selectedTerms,
    query: state.router.location.query,
    pathname: state.router.location.pathname,
    scrollPosition: state.App.scrollPosition,
    routerAction: state.router.action,
    shouldUpdateQuery: state.Archive.shouldUpdateQuery,
    filterIsOpen: state.Sidebar.filterIsOpen,
}))(Archive);