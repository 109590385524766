import React from 'react';
import { useInView } from 'react-intersection-observer';
import {CSSTransition} from 'react-transition-group';
import {Link} from 'react-router-dom';
import Arrow from '../../../assets/icons/arrow.js';

import {getPageLink} from '../../helpers/helpers.js';
import LinkWrapper from '../LinkWrapper'

import './title_text.scss';

export default function TitleText( {title, link, color, className} ) {

  const { ref, inView } = useInView({
    /* Optional options */
    // threshold: .5,
  });

  let title_element = <></>;
  if ( title && title.length ) {

    title_element = (
      <CSSTransition
        in={inView}
        // timeout={{enter: 1470, exit: 700}}
        timeout={{enter: 0, exit: 0}}
        unmountOnExit={false}
        classNames="title-text__title">
          <div className="title-text__title">
            <span className={color}>{title}</span>
          </div>
      </CSSTransition>
    );

  }

  let link_element = <></>;
  if ( link && link.title ) {
    
    // Create realtive url
    let path_array = link.url.split( '/' );
    let url = getPageLink(link.url);
    if ( path_array[0].indexOf('http') > -1) {
      path_array.shift();
      path_array.shift();
      path_array.shift();
      path_array.shift();
      url = '/' + path_array.join('/');
    }
    

    link_element = (
      <CSSTransition
        in={inView}
        // timeout={{enter: 1470, exit: 700}}
        timeout={{enter: 0, exit: 0}}
        unmountOnExit={false}
        classNames="title-text__link"
      >
        <div className="title-text__link">
          <LinkWrapper>
            <Link className={`title-text__link__link f-home-headline ${color}`} to={url}>
              <span className="title-text__link__arrow">
                <Arrow />
              </span>
              <span className="title-text__link__text">{link.title}</span>
            </Link>
          </LinkWrapper>
        </div>
      </CSSTransition> 
    );
  }

  return (
    <div ref={ref} className={`title-text f-home-headline ${color} ${className}`}>
      <div className='title-text__inner'>
        {title_element}
        {link_element}
      </div>
    </div>
  );

}