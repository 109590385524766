import React from 'react';
import {Route} from 'react-router-dom';

import './index.scss';

import RouteRenderer from './RouteRenderer.js'
import ApiFetcher from './ApiFetcher.js'

function Routes() {
		return (
			<div>
				<Route path="/no/:id" component={ApiFetcher}/>
				<Route exact path="/no" component={ApiFetcher}/>
				<Route exact path="/no/projects" component={ApiFetcher}/>
				<Route exact path="/no/projects/:id" component={ApiFetcher}/>
				<Route exact path="/no/archive" component={ApiFetcher}/>
				<Route exact path="/no/studio" component={ApiFetcher}/>
				<Route path="/no/people" component={ApiFetcher}/>
				<Route exact path="/no/contact" component={ApiFetcher}/>
				<Route exact path="/no/post/:id" component={ApiFetcher}/>

				<Route path="/:id" component={ApiFetcher}/>
				<Route exact path="/" component={ApiFetcher}/>
				<Route exact path="/projects" component={ApiFetcher}/>
				<Route exact path="/projects/:id" component={ApiFetcher}/>
				<Route exact path="/archive" component={ApiFetcher}/>
				<Route exact path="/studio" component={ApiFetcher}/>
				<Route path="/people" component={ApiFetcher}/>
				<Route exact path="/contact" component={ApiFetcher}/>
				<Route exact path="/post/:id" component={ApiFetcher}/>

				<RouteRenderer/>
			</div>
		);
	}



export default Routes
