import React from 'react';
import './index.scss';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'

import { ReactComponent as IconInformation } from './../../../assets/icons/IconInformation.svg'
import {generateLink, convertHTMLStringToReact} from './../../helpers/helpers'
import LinkWrapper from './../LinkWrapper'
import {ToggleFilter, ToggleSidebar} from './../../actions'

function Filter(props) {

  const selectedTerms = props.parent === "people" ? props.selectedPeopleTerms : props.selectedArchiveTerms;
  const selectedTermsAsArray = props.parent === "people" ? props.selectedPeopleTermsAsArray : props.selectedArchiveTermsAsArray;

  let tags = <></>;
  if ( props.apiData.taxonomies ) {
    tags = props.apiData.taxonomies.map((tax, key) => {

      let taxIsActive = selectedTerms ? selectedTerms[tax.displayname] ? true : false : false
      let selectedTaxTermsAsArray = taxIsActive ? selectedTerms[tax.displayname].split(',') : [];
      return (
        <div key={key} className={`filter-col ${tax.displayname} ${taxIsActive ? 'active' : ''}`}>
          <div className="title">{tax.displayname}</div>
          <ul>
            
            {tax.terms.map((term, termKey) => {
              
              let termIsSelected = selectedTaxTermsAsArray.includes(term.slug);
              
              return (
                <li key={termKey} className={`${termIsSelected ? 'active' : ''}`}>
                  <LinkWrapper>
                    <Link to={`/${props.parent}?${generateLink(tax.displayname, term.slug, termIsSelected, selectedTerms, selectedTermsAsArray)}`}>{convertHTMLStringToReact(term.name)}</Link>
                  </LinkWrapper>
                </li>
              )
            })}

          </ul>
            
        </div>
      );
    });
  }


  return (
    <div>
      <LinkWrapper>
        <div
          className={`side-btn toggle-filter-btn${props.filterIsOpen ? ' open' : ''}`}
          style={{transform: `translateZ(0) translateY(0px)`}}
          onClick={() => ToggleFilter()}
        >
          
          <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g fill="currentColor">
              <rect y="16" width="21" height="2" />
              <rect y="8" width="12" height="2.02817" />
              <rect width="7" height="2.02817" />
              <path d="M25 0H11L16.3529 7.5332V13.1831L19.6471 10.829V7.5332L25 0Z" />
            </g>
          </svg>

        </div>
      </LinkWrapper>

      <div className={`sidebar archivefilter ${props.filterIsOpen ? 'open' : 'closed'}`}>
          <div className="inner">
      
            <LinkWrapper className="sidebar__toggle__container">
              <div className={`sidebar__toggle toggle-sidebar-btn${props.filterIsOpen ? ' open' : ''}`} onClick={() => ToggleFilter()}>
                <div className="inner">
                  <div className="open">
                    <IconInformation />
                  </div>
                  <div className="close" />
                </div>
              </div>
            </LinkWrapper>

            {tags}
          </div>
      </div>
    </div>
  );
}

export default connect((state, ownProps) => ({
  apiData: state.App.pageData[ownProps.dataKey].data,
	filterIsOpen: state.Sidebar.filterIsOpen,
  selectedArchiveTerms: state.Archive.selectedTerms,
  selectedArchiveTermsAsArray: state.Archive.selectedTermsAsArray,
  selectedPeopleTerms: state.People.selectedTerms,
  selectedPeopleTermsAsArray: state.People.selectedTermsAsArray,
}))(Filter);
