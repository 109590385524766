import React, {useState} from 'react';
import './index.scss';

function LazyImageComponent(props) {
	
	const [isLoaded, setIsLoaded] = useState(false);

	const { image, url, alt, width, height, sizes, noCaption } = props;
	let orientation;
	
	if ( image && image.id ) {

		const sizeKeys = Object.keys(image.sizes).filter(key => {
			
			return ( key.indexOf("-width") === -1 && key.indexOf("-height") === -1 ) ? key : null;

		});

		const srcset = sizeKeys.map(key => `${image.sizes[key]} ${image.sizes[key + "-width"]}w`).join(",");
		const imageElement = <img src={image.sizes.large} srcSet={srcset} sizes={sizes} alt={image.alt} onLoad={() => setIsLoaded(true)}/>;
		orientation = image.width > image.height ? 'landscape' : 'portrait';

		if ( image.caption && image.caption.length && !noCaption) {

			return (
		
				<figure className={`media__figure`}>
						<div className={`media media--${orientation} lazy-image-component ${isLoaded ? 'loaded' : 'lazyloading'}`}>
							{imageElement}
						</div>
					<figcaption className="media__caption">{image.caption}</figcaption>
				</figure>
	
			);

		}

		return (
		
			<div className={`media media--${orientation} lazy-image-component ${isLoaded ? 'loaded' : 'lazyloading'}`}>
				{imageElement}
			</div>

		);

	}

	const altText = alt && alt.length ? alt : '';
	orientation = width > height ? 'landscape' : 'portrait';
	
  return (
    	<div className={`media media--${orientation} lazy-image-component ${isLoaded ? 'loaded' : 'lazyloading'}`}>
				<img src={url} alt={altText} onLoad={() => setIsLoaded(true)}/>
			</div>
  );

}

export default LazyImageComponent;
