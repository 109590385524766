import React from 'react';
import {connect} from 'react-redux'
import NodeGroup from 'react-move/NodeGroup'

import BezierEasing from 'bezier-easing'

import People from './../People' 
import Projects from './../Projects' 
import ProjectSingle from './../ProjectSingle' 
// import Frontpage from './../Frontpage'
import ProjectsArchive from './../ProjectsArchive'
import Page from './../Page'
import Studio from './../Studio'
// import Contact from './../Contact'

import Brandlogo from '../Brandlogo';
// import LinkWrapper from '../LinkWrapper';
// import { NavLink } from 'react-router-dom';

function RouteRenderer(props) {
	
	var easingInAlt = BezierEasing(0, 0.65, 0.2, 1);
	var easingOut = BezierEasing(0.55, 0, 1, 0.5);

	// const onScroll = (e) => {
	// 	if (props.scrollIsActive) {
	// 		props.dispatch({
	// 			type: 'SET_SCROLL_POSITION',
	// 			scrollPosition: e.target.scrollTop
	// 		})
	// 	}
	// }

	// console.trace('ROUTE RENDERER');

	return(
		<div>
			<NodeGroup
				data={props.pagesToShow}
				keyAccessor={(d) => d.key}
				start={(d) => ({opacity: 0, yPos: 100})}
				enter={() => ([
					{yPos: [0], timing: {delay: 400, duration: 600, ease: easingInAlt}},
					{opacity: [1], timing: {delay: 400, duration: 200}}
					])}
				leave={(data) => ({
					opacity: [0], 
					yPos: [-30], 
					timing: {duration: 400, ease: easingOut}, 
					events: {
						start: () => {
							// console.log('leave start', data, props);
						},
						end: () => {
						window.scrollTo(0, 0);
						// setTimeout(() => {
						// 	props.dispatch({type: 'PAGE_TRANSITION_COMPLETE'})
						// }, 100);
					}}
				})}
				>
					{(nodes) => (
						<div>
						{nodes.map(({key, data, state}) => (
								// <div key={key} className="loadwrapper" style={{opacity: state.opacity, transform: `translateY(${state.yPos}px)`}}>
								<div key={key} className="loadwrapper" style={{opacity: state.opacity}}>

									<div key={key} className={`scroll-wrapper ${!props.scrollIsActive ? 'disable' : ''}`}
									// onScroll={onScroll}
									>

										<div className="scroll-wrapper__header">
											
											<Brandlogo className="scroll-wrapper__logo" />

										</div>
										
										{
											data.component === 'Projects' ? <Projects dataKey={data.key}/> :
											// data.component === 'Frontpage' ? <Frontpage dataKey={data.key}/> :
											data.component === 'People' ? <People dataKey={data.key}/> : 
											data.component === 'Studio' ? <Studio dataKey={data.key}/> : 
											data.component === 'Archive' ? <ProjectsArchive dataKey={data.key}/> : 
											data.component === 'Projectsingle' ? <ProjectSingle dataKey={data.key}/> :
											// data.component === 'Contact' ? <Contact dataKey={data.key}/> :
											data.component === 'Page' ? <Page dataKey={data.key}/> : null	
										}
									</div>
										
								</div>
							))}
							</div>
						)
					}
			</NodeGroup>
		</div>
		)
}

export default connect((state) => ({
	lang: state.App.lang,
	pagesToShow: state.App.pagesToShow,
	scrollIsActive: state.App.scrollIsActive
}))(RouteRenderer)
