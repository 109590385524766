import React from 'react';

const arrow = () => {
  return (
    <svg className={`c-arrow`} width="61" height="48" viewBox="0 0 61 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1818 30.1364C12.4242 30.1364 9.90152 29.4545 7.61364 28.0909C5.34091 26.7273 3.52273 24.9091 2.15909 22.6364C0.81061 20.3485 0.136368 17.8182 0.136368 15.0455C0.136368 12.9697 0.522731 11.0227 1.29546 9.20454C2.08334 7.38636 3.16667 5.78788 4.54546 4.40909C5.92425 3.0303 7.52273 1.95454 9.34091 1.18182C11.1591 0.393939 13.1061 0 15.1818 0H17.5V5H15.1818C13.3485 5 11.6667 5.45455 10.1364 6.36364C8.62122 7.27273 7.4091 8.48485 6.5 10C5.59091 11.5152 5.13637 13.197 5.13637 15.0455C5.13637 16.8939 5.58334 18.5833 6.47728 20.1136C7.38637 21.6439 8.59849 22.8636 10.1136 23.7727C11.6439 24.6818 13.3333 25.1364 15.1818 25.1364H55.5V30.1364H15.1818ZM40.4546 48L37 44.5L53.9091 27.6364L37 10.7273L40.4546 7.27273L60.8182 27.6364L40.4546 48Z" fill="currentColor"/>
    </svg>
  );
}

export default arrow;