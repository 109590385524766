import React from 'react';
import './App.scss';
import {connect} from 'react-redux'

import NavigationMenu from './NavigationMenu'
// import Brandlogo from './Brandlogo'
import Routes from './Routes';
import Loader from './Loader'
import CurrentSingleName from './CurrentSingleName'
import Cursor from './Cursor'
 
function App(props) {
	const mouseMove = (e) => {
    props.dispatch({
      type: 'SET_CURSOR_POSITION',
      xPos: e.clientX,
      yPos: e.clientY
    })
    // tjeckSlideDir(e.clientY);
  }


  const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  }
  window.addEventListener('resize', appHeight);
  appHeight();

  return (
    <div className="App"
        onMouseMove={(e) => mouseMove(e)} 
        onMouseEnter={() => props.dispatch({type: 'SHOW_CURSOR', show: true})} 
        onMouseLeave={() => props.dispatch({type: 'SHOW_CURSOR', show: false})} 
    >
		{/*<RemoveScrollBar />*/}
      <Cursor/>
      {/*<CurrentMenuName/>*/}
      <CurrentSingleName/>
      <Loader/>
    	{/* <Brandlogo/> */}
    	<NavigationMenu/>
    	<Routes/>
      {/* <AnimatedScrollHandler/> */}
    </div>
  );
}

export default connect()(App);
