import React from 'react';
import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import {ReactComponent as MenuDots} from './../../../assets/icons/menudots.svg'
import Arrow from '../../../assets/icons/arrow.js';
// import {ReactComponent as ArrowLink} from './../../../assets/icons/arrowLink.svg'

import './index.scss';

import LinkWrapper from './../LinkWrapper'

function NavigationMain(props) {

	let { lang, menuIsShowing, isMobile } = props;
	const lang_prefix = lang === 'en' ? '' : '/' + lang;
	const menu_items = [
		{
			url: `${lang_prefix}/`,
			exact: true,
			title: lang === 'no' ? 'Hjem' : 'Home',
		},
		{
			url: `${lang_prefix}/projects`,
			title: lang === 'no' ? 'Prosjekter' : 'Projects',
		},
		{
			url: `${lang_prefix}/people`,
			title: lang === 'no' ? 'Mennesker' : 'People',
		},
		{
			url: `${lang_prefix}/studio`,
			title: lang === 'no' ? 'Studio' : 'Studio',
		},
		{
			url: `${lang_prefix}/contact`,
			title: lang === 'no' ? 'Kontakt' : 'Contact',
		}
	];
		
	const menu_item_elements = menu_items.map((item, index) => {
		return (
			<li key={`menuItem_${index}`}>
				<LinkWrapper className="navigationmain__item__linkwrapper">
					<NavLink to={item.url} exact={item.exact} className="navigationmain__item__link">
						<div className="navigationmain__item__arrow">
							<Arrow />
						</div>
						<span className="navigationmain__item__text">{item.title}</span>
					</NavLink>
				</LinkWrapper>
			</li>
		);
	});


  return (
  		<div className={`navigation-wrapper ${menuIsShowing ? 'open' : ''} ${isMobile ? 'mobile' : 'desktop'}`}>
  			
				{/* <div className="navigation__lang">
					<LinkWrapper>
						<NavLink to="/" exact={true} className={`${lang === 'en' ? 'active': ''}`}>Eng</NavLink>
					</LinkWrapper>
					<LinkWrapper>
						<NavLink to="/no" exact={true} className={`${lang === 'no' ? 'active': ''}`}>No</NavLink>
					</LinkWrapper>
				</div> */}

  			<div className="navigationmain">
		    	<ul>
						{menu_item_elements}
			    </ul>
			</div>
		    <LinkWrapper>
    			<MenuDots
    				onClick={() => props.dispatch({type: 'TOGGLE_MENU_IS_SHOWING'})}
    			/>
		    </LinkWrapper>
		 </div>
  );
}

export default connect(state => ({
	menuIsShowing: state.App.menuIsShowing,
	isMobile: state.Device.isMobile,
	lang: state.App.lang
	}))(NavigationMain);