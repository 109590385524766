export const socialScienceData = {
  size: {
    portrait: 0.9,
    landscape: 0.9
  },
  segments: [
  {
    "point": {
      "x": 231.14706976745475,
      "y": 310.564930232562
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": 36.21209302325582,
      "y": 25.96316279069771
    }
  },
  {
    "point": {
      "x": 357.51581395349825,
      "y": 317.0691162790739
    },
    "handleIn": {
      "x": -38.68576744186049,
      "y": 22.108279069767377
    },
    "handleOut": {
      "x": 38.685767441860435,
      "y": -22.111255813953505
    }
  },
  {
    "point": {
      "x": 416.0594418604738,
      "y": 204.88753488372996
    },
    "handleIn": {
      "x": 3.9888372093022895,
      "y": 44.380279069767425
    },
    "handleOut": {
      "x": 49.53897674418607,
      "y": 44.84167441860467
    }
  },
  {
    "point": {
      "x": 600.3437209302342,
      "y": 269.0780465116351
    },
    "handleIn": {
      "x": -66.67609302325582,
      "y": 4.3609302325581325
    },
    "handleOut": {
      "x": 66.67906976744189,
      "y": -4.357953488372118
    }
  },
  {
    "point": {
      "x": 774.7035348837136,
      "y": 181.43972093024607
    },
    "handleIn": {
      "x": -43.27888372093025,
      "y": 50.91125581395349
    },
    "handleOut": {
      "x": 9.495813953488323,
      "y": 55.74846511627908
    }
  },
  {
    "point": {
      "x": 870.0933023255676,
      "y": 314.75320930233266
    },
    "handleIn": {
      "x": -49.699720930232616,
      "y": -26.978232558139496
    },
    "handleOut": {
      "x": 49.699720930232616,
      "y": 26.981209302325624
    }
  },
  {
    "point": {
      "x": 1033.8529302325321,
      "y": 322.12660465117017
    },
    "handleIn": {
      "x": -51.92334883720946,
      "y": 22.40595348837212
    },
    "handleOut": {
      "x": -29.600744186046654,
      "y": 10.847255813953552
    }
  },
  {
    "point": {
      "x": 957.2553488371858,
      "y": 375.2674418604695
    },
    "handleIn": {
      "x": 18.723720930232616,
      "y": -25.364837209302323
    },
    "handleOut": {
      "x": -18.72074418604643,
      "y": 25.364837209302266
    }
  },
  {
    "point": {
      "x": 942.5919069767166,
      "y": 464.5876279069739
    },
    "handleIn": {
      "x": -11.823627906976753,
      "y": -29.225674418604626
    },
    "handleOut": {
      "x": 8.27237209302325,
      "y": 20.44725581395346
    }
  },
  {
    "point": {
      "x": 985.1593488371743,
      "y": 513.9866976744106
    },
    "handleIn": {
      "x": -18.946976744185918,
      "y": -10.52576744186058
    },
    "handleOut": {
      "x": -47.52669767441864,
      "y": -2.7177674418605875
    }
  },
  {
    "point": {
      "x": 849.3573023255577,
      "y": 537.4613023255697
    },
    "handleIn": {
      "x": 39.83479069767452,
      "y": -31.8124651162791
    },
    "handleOut": {
      "x": -42.716279069767324,
      "y": 34.11646511627907
    }
  },
  {
    "point": {
      "x": 816.6547906976523,
      "y": 685.5602790697358
    },
    "handleIn": {
      "x": -24.379534883720908,
      "y": -48.9287441860464
    },
    "handleOut": {
      "x": -27.043720930232553,
      "y": -48.3661395348837
    }
  },
  {
    "point": {
      "x": 693.4681860465041,
      "y": 580.8294883720725
    },
    "handleIn": {
      "x": 53.68855813953496,
      "y": 13.716837209302298
    },
    "handleOut": {
      "x": -53.68855813953485,
      "y": -13.71981395348837
    }
  },
  {
    "point": {
      "x": 552.2216744186196,
      "y": 639.8910697674065
    },
    "handleIn": {
      "x": 23.01320930232555,
      "y": -50.411162790697745
    },
    "handleOut": {
      "x": 19.682232558139503,
      "y": -60.567813953488326
    }
  },
  {
    "point": {
      "x": 485.3133953488666,
      "y": 477.5275348837125
    },
    "handleIn": {
      "x": 56.63851162790695,
      "y": 29.115534883720898
    },
    "handleOut": {
      "x": -56.63851162790701,
      "y": -29.115534883720898
    }
  },
  {
    "point": {
      "x": 314.33813953495246,
      "y": 517.6064186046333
    },
    "handleIn": {
      "x": 37.801674418604705,
      "y": -51.253581395348874
    },
    "handleOut": {
      "x": 22.781023255813977,
      "y": -26.314418604651223
    }
  },
  {
    "point": {
      "x": 341.8998139535583,
      "y": 420.32046511628215
    },
    "handleIn": {
      "x": 5.593302325581362,
      "y": 34.35162790697677
    },
    "handleOut": {
      "x": -3.6584186046511604,
      "y": -22.465488372092977
    }
  },
  {
    "point": {
      "x": 312.62948837217846,
      "y": 359.6812093023448
    },
    "handleIn": {
      "x": 15.16651162790697,
      "y": 16.952558139534858
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  }
]}