import React from 'react';
import './index.scss';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'

import {generateLink, convertHTMLStringToReact} from './../../helpers/helpers'
import LinkWrapper from './../LinkWrapper'

function ActiveFilterTags(props) {

	const selectedTerms = props.parent === "people" ? props.selectedPeopleTerms : props.selectedArchiveTerms;
  const selectedTermsAsArray = props.parent === "people" ? props.selectedPeopleTermsAsArray : props.selectedArchiveTermsAsArray;


	let selectedTags = <></>;
	if ( selectedTermsAsArray ) {
		
		selectedTags = selectedTermsAsArray.map((tax, taxKey) => {
			let currentTax = props.taxonomyAll.find((taxAll) => taxAll.displayname === tax.tax);

			let terms = <></>;
			if ( currentTax ) {
				terms = tax.terms.map( (term, termKey) => {

					let currentTerm = currentTax.terms.find((taxToSearch) => taxToSearch.slug === term);

					return (
						<LinkWrapper key={`${taxKey}_${termKey}`}>
							<Link className="term" to={`/${props.parent}?${generateLink(tax.tax, term, true, selectedTerms, selectedTermsAsArray)}`}>{convertHTMLStringToReact(currentTerm.name)}</Link>
						</LinkWrapper>
					);
				});
			}

			return terms;
        
		});

	}

  return (
    <div className={`active-filter-tags ${selectedTermsAsArray.length === 0 ? 'hide' : 'show'}`}>
    	{selectedTags}
    </div>
  );
}

export default connect((state, ownProps) => ({
	selectedArchiveTerms: state.Archive.selectedTerms,
  selectedArchiveTermsAsArray: state.Archive.selectedTermsAsArray,
  selectedPeopleTerms: state.People.selectedTerms,
  selectedPeopleTermsAsArray: state.People.selectedTermsAsArray,
	taxonomyAll: ownProps.taxonomyAll
}))(ActiveFilterTags);
