import React from 'react';
import {
  Border,
  Headline,
  HeadlineImageText,
  HeadlineText,
  ID,
  ImageFull,
  ImageLarge,
  LargeText,
  Lasso,
  LatestNews,
  People,
  Text,
  TextColumns,
  TwoImages,
  TwoColumnsImageText,
  Video
} from './Sections'

function Sections(props) {

  const { lang, latestNews, sections } = props;
  
  let sectionItems = [<div key="asdf"></div>];

  if (sections) {
    sectionItems = sections.map((section, key) => {
     
      switch (section.acf_fc_layout) {
        case 'border':
          return <Border key={key} first={key===0} />;
        case 'headline':
          return <Headline key={key} first={key===0} data={section}/>;
        case 'headline_and_text':
          return <HeadlineText key={key} first={key===0} data={section}/>;
        case 'headline_image_and_text':
          return <HeadlineImageText key={key} first={key===0} data={section}/>;
        case 'id':
          return <ID key={key} first={key===0} data={section} />;
        case 'image_large':
          return <ImageLarge key={key} first={key===0} data={section}/>;
        case 'image_full':
          return <ImageFull key={key} first={key===0} data={section} keepVisible={key===sections.length-2 && sections[key+1] && sections[key+1].acf_fc_layout === 'latest_news'}/>;
        case 'lasso':
          return <Lasso key={key} first={key===0} data={section} sectionVal={key} />;
        case 'large_text':
          return <LargeText key={key} first={key===0} data={section}/>;
        case 'latest_news':
          return <LatestNews key={key} first={key===0} data={latestNews} lang={lang} last={key===sections.length-1 && sections[key-1].acf_fc_layout==='image_full'} />;
        case 'people':
          return <People key={key} first={key===0} data={section}/>;
        // case 'slideshow':
          // return <Slideshow key={key} data={section} sectionVal={key} />;
        case 'text':
          return <Text key={key} first={key===0} data={section}/>;
        case 'text_columns':
          return <TextColumns key={key} first={key===0} data={section}/>;
        case 'two_images':
          return <TwoImages key={key} first={key===0} data={section} />
        case 'two_columns_image_and_text':
          return <TwoColumnsImageText key={key} first={key===0} data={section} />
        case 'video':
          return <Video key={key} first={key===0} data={section} />
        default:
          console.log(section.acf_fc_layout);
          return null;
      }


    });
  }

  return (
    <div className='sections'>
      {sectionItems}
  </div>
  );
}

export default Sections;