export const architectureData = {
  size: {
    portrait: 0.8,
    landscape: 0.9
  },
  segments: [
  {
    "point": {
      "x": 568.9197209302326,
      "y": 653.5567212435856
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": 50.33674418604642,
      "y": -78.71999999999991
    }
  },
  {
    "point": {
      "x": 648.9733023255815,
      "y": 388.6086282203298
    },
    "handleIn": {
      "x": -1.6759069767442725,
      "y": 93.42213953488374
    },
    "handleOut": {
      "x": 0.7560930232557439,
      "y": -42.13879069767438
    }
  },
  {
    "point": {
      "x": 622.2808372093023,
      "y": 266.85383752265545
    },
    "handleIn": {
      "x": 22.388093023255806,
      "y": 35.70902325581392
    },
    "handleOut": {
      "x": -22.385116279069734,
      "y": -35.709023255813975
    }
  },
  {
    "point": {
      "x": 515.5199069767441,
      "y": 215.4424886854461
    },
    "handleIn": {
      "x": 41.073116279069836,
      "y": -9.442232558139523
    },
    "handleOut": {
      "x": -32.77097674418593,
      "y": 7.534139534883735
    }
  },
  {
    "point": {
      "x": 444.92046511627916,
      "y": 281.9727212435856
    },
    "handleIn": {
      "x": 12.555906976744097,
      "y": -31.193302325581385
    },
    "handleOut": {
      "x": -23.73060465116282,
      "y": 58.95441860465121
    }
  },
  {
    "point": {
      "x": 459.6226046511628,
      "y": 463.48267473195773
    },
    "handleIn": {
      "x": -26.513860465116238,
      "y": -56.05804651162788
    },
    "handleOut": {
      "x": 34.32781395348837,
      "y": 72.57599999999996
    }
  },
  {
    "point": {
      "x": 640.8735813953488,
      "y": 610.4504886854461
    },
    "handleIn": {
      "x": -77.57990697674416,
      "y": -20.658604651162705
    },
    "handleOut": {
      "x": 77.57990697674427,
      "y": 20.661581395348776
    }
  },
  {
    "point": {
      "x": 875.2951627906975,
      "y": 584.910023569167
    },
    "handleIn": {
      "x": -72.26344186046504,
      "y": 34.979720930232475
    },
    "handleOut": {
      "x": 72.26344186046526,
      "y": -34.97972093023259
    }
  },
  {
    "point": {
      "x": 1050.7772093023257,
      "y": 424.4426747319577
    },
    "handleIn": {
      "x": -43.094325581395424,
      "y": 67.74176744186047
    },
    "handleOut": {
      "x": -63.75888372093027,
      "y": -48.392930232558115
    }
  },
  {
    "point": {
      "x": 839.4879069767442,
      "y": 268.46127938312054
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  },
  {
    "point": {
      "x": 619.5600930232558,
      "y": 356.11746542963215
    },
    "handleIn": {
      "x": 67.45897674418597,
      "y": -27.832558139534854
    },
    "handleOut": {
      "x": -135.1114418604651,
      "y": 55.73953488372092
    }
  },
  {
    "point": {
      "x": 214.22279069767444,
      "y": 523.3330933366088
    },
    "handleIn": {
      "x": 135.1114418604651,
      "y": -55.739534883720864
    },
    "handleOut": {
      "x": 2.274232558139545,
      "y": 37.33432558139532
    }
  },
  {
    "point": {
      "x": 217.53888372093027,
      "y": 631.6955119412601
    },
    "handleIn": {
      "x": -2.274232558139545,
      "y": -37.33134883720936
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  }
]
}