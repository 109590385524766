import React from 'react';
import {connect} from 'react-redux'

// import './index.scss';
import Footer from './../Footer'

import Sections from '../Sections';

function Page(props) {

  // const { linkHover, setLinkHover } = useEffect();
  // const header_link_hover_modifier = linkHover ? 'hover' : 'no-hover';

  const { apiData, lang, latestNews } = props;
  let data = apiData.id ? apiData : apiData[0];

  let modifier = '';
  if ( data ) {
    if ( data.type && data.type !== "") modifier += " " + data.type;
    if ( data.slug && data.slug !== "") modifier += " " + data.slug;
  }

  
  let sectionsData;
  if ( data && data.acf && (data.acf.sections || data.acf.sections_sections) ) {

    // Use for rendering posts/pages with acf fields
    sectionsData = data.acf.sections ? data.acf.sections : data.acf.sections_sections;
  
  } else if ( data && data.content && data.content.rendered ) {

    // Use for rendereing "normal" wordpress posts/pages
    sectionsData = [{
      acf_fc_layout: "headline_and_text",
      headline_and_text_headline: data.title.rendered,
      headline_and_text_text: data.content.rendered,
    }];

  }

  let sectionsElement = <></>;
  if ( sectionsData ) {
    sectionsElement = <Sections
      lang={lang}
      latestNews={latestNews}
      sections={sectionsData}
    />;
  }

  return (
    <div className={`page normal ${modifier}`}>
      {sectionsElement}
      <Footer/>
    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data,
    lang: state.App.lang,
    latestNews: state.App.latestNews,
}))(Page)
