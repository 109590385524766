import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import ScrollVisibilityComponent from '../ScrollVisibilityComponent';
import LazyImageComponent from './../LazyImageComponent'
import LinkWrapper from './../LinkWrapper'

function ArchiveList(props) {

    const {projects, archiveIsLoading, filterIsAnimating, achiveLayoutIsWide, layoutSimpleGrid, lang} = props;
    
    const projectItems = () => {

        const sizes = layoutSimpleGrid ? '20vw' : '40vw';
        return projects.map((data, key)  => {

            const url = lang === 'en' ? `/projects/${data.slug}` : `/${lang}/projects/${data.slug}`;
            return (
                <div key={key} className="project-thumb">
                    <ScrollVisibilityComponent>
                        <LinkWrapper cursorText={!layoutSimpleGrid ? data.title : null}>
                            <Link to={url}>
                                <LazyImageComponent image={data.thumb} sizes={sizes} noCaption />
                                <div className="title">{data.title}</div>
                            </Link>
                        </LinkWrapper>    
                    </ScrollVisibilityComponent>
                </div>
            );

        }); 
    }
	return(
		<div className={`${archiveIsLoading ? 'loading' : ''} ${!achiveLayoutIsWide ? 'open' : ''}`}>
            <div className={`archive-list archive-list--${layoutSimpleGrid ? 'simple' : 'spacy' } ${props.isMobileSize ? 'archive-list--mobile' : '' } l-columns-container l-columns-container--outmost ${filterIsAnimating ? 'hide' : ''}`}>
                {projectItems()}
                {projects.length === 0 ? <div className="notfound">no projects found</div> : null}
            </div>
	    </div>

    )
}

export default connect((state) => ({
    archiveIsLoading: state.Archive.archiveIsLoading,
    isMobileSize: state.Device.isMobileSize,
    lang: state.App.lang,
    filterIsAnimating: state.Sidebar.filterIsAnimating,
    achiveLayoutIsWide: state.Sidebar.achiveLayoutIsWide
}))(ArchiveList)