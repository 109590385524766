import React from 'react';
import {Link} from 'react-router-dom'
import LazyImageComponent from './../LazyImageComponent'
import ScrollVisibilityComponent from './../ScrollVisibilityComponent'
import LinkWrapper from './../LinkWrapper'

import './relatedProjects.scss'

export const RelatedProjects = ({data, lang}) => (

        <ScrollVisibilityComponent>
    		<div className="project-single__related border-top">
                <div className="l-columns-container--outmost">
                    <h3 className="project-single__related__title f-section-headline">{lang === 'en' ? "More projects" : "Flere prosjekter"}</h3>

                    <div className="project-single__related__items l-columns-container">
                        {data ? data.map((ele, key) => (
                            <div key={key} className="project-single__related__item">
                                <LinkWrapper>
                                    <Link to={`/projects/${ele.slug}`}>
                                        <div className="inner">
                                            <LazyImageComponent width={ele.thumb.sizes[`project_thumb_related-width`]} height={ele.thumb.sizes[`project_thumb_related-height`]} url={ele.thumb.sizes.project_thumb_related}/>
                                            {/*<div className="category">{ele.category}</div>
                                            <div className="year">{ele.year}</div>*/}
                                            <div className="title">{ele.title}</div>
                                        </div>
                                    </Link>
                                </LinkWrapper>
                            </div>
                        )) : null}
                    </div>
                </div>
        	</div>
        </ScrollVisibilityComponent>
	)