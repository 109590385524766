const initialState = {
	selectedTerms: {},
	selectedTermsAsArray: [],
	archiveIsLoading: false,
	shouldUpdateQuery: false
}

const Archive = (state = initialState, action) => {
	switch(action.type) {

		case 'SET_ACTIVE_TAGS':

			let selectedTermsAsArray = []
			let keys = Object.keys(action.terms).filter(ele => ele !== "")
			for (const key of keys) {
				selectedTermsAsArray.push({
					tax: key,
					terms: action.terms[key].split(',')
				})
			}

			return {
				selectedTerms: action.terms,
				selectedTermsAsArray: selectedTermsAsArray,
				shouldUpdateQuery: action.shouldUpdateQuery
			};

		case 'ARCHIVE_IS_LOADING':
			return {
				...state,
				archiveIsLoading: action.val
			}

		default:
			return state
	}
}

export default Archive