import React from 'react';
import './index.scss';

import {connect} from 'react-redux'

function Cursor(props) {
  return props.isMobile ? null : (
    <div className={`cursor ${!props.show ? 'hide' : ''} ${props.active ? 'active' : ''} ${props.isFrontpage && !props.menuIsShowing ? 'front' : ''}` } style={{transform: `translate3d(${props.position.x}px, ${props.position.y}px, 0px)`}}>
    	<div className="display cursor__pointer"/>
      <p className={`cursor__text cursor__text--${props.textSize}`}>{props.text}</p>
    </div>
  );
}


export default connect((state) => ({
	position: state.Cursor.position,
	active: state.Cursor.active,
  show: state.Cursor.show,
  rotation: state.Cursor.rotation,
  text: state.Cursor.text,
  textSize: state.Cursor.textSize,
  isFrontpage: state.App.pagesToShow[0] ? state.App.pagesToShow[0].component === 'Frontpage' ? true : false : null,
  menuIsShowing: state.App.menuIsShowing,
  isMobile: state.Device.isMobile
}))(Cursor);
