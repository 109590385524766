const initialState = {
	position: {
		x: window.innerWidth/2,
		y: window.innerHeight/2
	},
	active: false,
	show: false,
	text: '',
	textSize: 'large',
	rotation: 180
}

const Cursor = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_CURSOR_POSITION':
			return {
				...state,
				position: {
					x: action.xPos,
					y: action.yPos
				}
			}

		case 'SET_CURSOR_ACTIVE':
			return {
				...state,
				active: action.active
			}

		case 'SET_CURSOR_TEXT':
			return {
				...state,
				text: action.text
			}

		case 'SET_CURSOR_TEXT_SIZE':
			return {
				...state,
				textSize: action.textSize
			}

		case 'SHOW_CURSOR':
			return {
				...state,
				show: action.show
			}

		case 'ROTATE_CURSOR':
			let rot = state.rotation + 180;
			return {
				...state,
				rotation: rot
			}

		case 'INIT_CURSOR_ROTATION':
			return {
				...state,
				rotation: 180
			}

		default:
			return state
	}
}

export default Cursor