import React from 'react';
import {connect} from 'react-redux';

import './index.scss';
import Footer from './../Footer'
// import BottomPageLink from './../BottomPageLink'
// import Clients from './../Clients'

import {convertHTMLStringToReact} from './../../helpers/helpers.js'
import Sections from '../Sections';

function Studio(props) {

    // const { linkHover, setLinkHover } = useEffect();

    const linkClickHandler = (e) => {
        props.dispatch({type: 'SET_CURSOR_ACTIVE', active: false})
        // const targetLink = e.target.closest('a');
        // if (!targetLink) return;
        // e.preventDefault();
        // const targetId = targetLink.getAttribute("href").substring(1,99);
        // let ele = document.getElementById(targetId).parentElement;
        // let offsetTop = ele.offsetTop;
        // animateScroll(offsetTop + 24);
    }

    // const header_link_hover_modifier = linkHover ? 'hover' : 'no-hover';

  return (
    <div className="page studio normal">        
    	<div className="page-section l-columns-container l-columns-container--outmost">
            <div className={`page-headline`}>
                <div className="page-headline__text f-page-headline" onClick={linkClickHandler}>
                    {convertHTMLStringToReact(props.apiData.acf.headertext, props.apiData.acf.headertext_images)}
                </div>
            </div>
    	</div>

        <Sections
            lang={props.lang}
            latestNews={props.latestNews}
            sections={props.apiData.acf.sections_sections}
        />

        {/* <BottomPageLink data={props.bottomPageLink}/> */}
        {/* <Clients data={props.apiData.acf.clients}/> */}
        <Footer/>

    </div>
  );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data[0],
    lang: state.App.lang,
    latestNews: state.App.latestNews,
    footerData: state.App.footerData,
    bottomPageLink: {
        title: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_title,
        image1: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_image1,
        image2: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_image2,
        text: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_text,
        linkObj: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_link,
        linktext: state.App.pageData[ownProps.dataKey].data[0].acf.bottom_pagelink_linktext
    }
}))(Studio)
