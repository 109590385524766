import {useEffect, useRef} from 'react'
import {connect} from 'react-redux'
import {getApiBase, isMobile} from './../../helpers/helpers'

// const getPages = () => {
//   return new Promise((resolve, reject) => {
//     const endpoint = `${getApiBase()}/wp-json/better-rest-endpoints/v1/pages?content=false&acf=false&media=false&per_page=99`
//   fetch(endpoint)
//     .then(checkStatus)
//     .then(response => response.json())
//     .then(pages => {
//       resolve(pages);
//     })
//     .catch(error => console.error(error))
//   })
// }
// const identifyComponent = (page) => {
//   // this is where you map your template names to a component 
//   const components = {
//     'home': Home,
//     'about': About
//   }
//  // check the template from the response 
//  if(page.template !== 'default' && page.template){
//    return components[page.template];
//  } else {
//    return Page
//  }
// }

const fetchData = async (dispatch, match, archiveQuery) => {

	const siteMap = {
		'/': {
			'api': getApiBase()+'/wp-json/data/v2/front',
			'component': 'Page',
			'menuname': null,
			'id': 'home'
		},
		'/no': {
			'api': getApiBase()+'/wp-json/data/v2/front',
			'component': 'Page',
			'menuname': null,
			'id': 'home'
		},
		'/projects': {
			'api': getApiBase()+'/wp-json/data/v2/archive'+archiveQuery,
			'component': 'Archive',
			'menuname': 'projects',
			'id': 'projects'
		},
		'/no/projects': {
			'api': getApiBase()+'/wp-json/data/v2/archive'+archiveQuery,
			'component': 'Archive',
			'menuname': 'projects',
			'id': 'projects'
		},
		'/archive': {
			'api': getApiBase()+'/wp-json/data/v2/archive'+archiveQuery,
			'component': 'Archive',
			'menuname': 'projects',
			'id': 'projects'
		},
		'/no/archive': {
			'api': getApiBase()+'/wp-json/data/v2/archive'+archiveQuery,
			'component': 'Archive',
			'menuname': 'projects',
			'id': 'projects'
		},
		'/people': {
			'api': getApiBase()+'/wp-json/data/v2/people'+archiveQuery,
			'component': 'People',
			'menuname': 'people',
			'id': 'people'
		},
		'/no/people': {
			'api': getApiBase()+'/wp-json/data/v2/people'+archiveQuery,
			'component': 'People',
			'menuname': 'people',
			'id': 'people'
		},
		'/projects/:id': {
			'api': getApiBase()+'/wp-json/data/v2/singleproject?slug='+match.params.id+'&acf_format=standard',
			'component': 'Projectsingle',
			'menuname': 'projects'
		},
		'/no/projects/:id': {
			'api': getApiBase()+'/wp-json/data/v2/singleproject?slug='+match.params.id+'&acf_format=standard',
			'component': 'Projectsingle',
			'menuname': 'projects'
		},
		'/post/:id': {
			'api': getApiBase()+'/wp-json/wp/v2/posts/'+match.params.id,
			'menuname': null
		},
		'/no/post/:id': {
			'api': getApiBase()+'/wp-json/wp/v2/posts/'+match.params.id,
			'menuname': null
		},
		'/:id': {
			'api': getApiBase()+'/wp-json/wp/v2/pages?slug='+match.params.id+'&acf_format=standard',
			'menuname': null
		},
		'/no/:id': {
			'api': getApiBase()+'/wp-json/wp/v2/pages?slug='+match.params.id+'&acf_format=standard',
			'menuname': null
		}
	}
	
	if ( siteMap[match.path]) {

		const response = await fetch(siteMap[match.path].api);
		let jsonResponse = await response.json();
		
		let component = siteMap[match.path].component ? siteMap[match.path].component : 'Page';
		let id = siteMap[match.path].id ? siteMap[match.path].id : '';
		if (jsonResponse.id) id = jsonResponse.id;
		if (jsonResponse[0] && jsonResponse[0].id) id = jsonResponse[0].id;

		if ( match.url === "/studio" || match.url === "/studio/" || match.url === "/no/studio" || match.url === "/no/studio/" ) {
			component = "Studio";
			siteMap[match.path].menuname = "studio";
		} else if ( match.url === "/contact" || match.url === "/no/contact" ) {
			siteMap[match.path].menuname = "contact";
		} else if ( match.url === "/contact" || match.url === "/no/contact" ) {
		}

		dispatch({
			type: 'PAGE_HAS_LOADED',
			pageObj: {
				url: match.url,
				id: id,
				component: component,
				menuname: siteMap[match.path].menuname,
				data: jsonResponse
			}
		});
	}
}


const fetchLatestNews = async (dispatch) => {
	
	const response = await fetch(getApiBase()+'/wp-json/data/v2/latestNews');
	let jsonResponse = await response.json();

	dispatch({
		type: 'SET_LATEST_NEWS',
		data: jsonResponse
	});
	
}

// const fetchGlobalData = async (dispatch) => {

// 	const mainNav = await fetch(getApiBase()+'/wp-json/wp-api-menus/v2/menu-locations/main-menu');
// 	const mainNavJson = await mainNav.json();
// 	dispatch({type: 'SET_MAIN_NAV_DATA', data: mainNavJson});

// 	const footerNav = await fetch(getApiBase()+'/wp-json/wp-api-menus/v2/menu-locations/footer-menu');
// 	const footerNavJson = await footerNav.json();
// 	dispatch({type: 'SET_FOOTER_NAV_DATA', data: footerNavJson});

// 	const someNav = await fetch(getApiBase()+'/wp-json/wp-api-menus/v2/menu-locations/some-menu');
// 	const someNavJson = await someNav.json();
// 	dispatch({type: 'SET_SOME_NAV_DATA', data: someNavJson});

// 	const langNav = await fetch(getApiBase()+'/wp-json/wp-api-menus/v2/menu-locations/lang-menu');
// 	const langNavJson = await langNav.json();
// 	dispatch({type: 'SET_LANG_NAV_DATA', data: langNavJson});

// }


function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function ApiFetcher(props) {
	const receiveUrl = props.match.url;
	const prevUrl = usePrevious(receiveUrl);

	let current_url_lang = ( window.location.href.split('/')[3] === 'no' ) ? 'no' : 'en';

	useEffect(() => {

		if (receiveUrl !== prevUrl) {
			
			// console.log('use effect B', props);

			props.dispatch({type: 'PAGE_IS_LOADING'})
			if (isMobile()) props.dispatch({type: 'SET_MENU_IS_SHOWING', val: false});

			// use existing api-data from redux if exists
			if (props.pageData) {
				if (!props.onArchiveAndShouldReload) {
					if (props.pageData[props.match.url]) {
						
						window.scrollTo(0, 0);

						props.dispatch({type: 'SET_CURRENT_PAGE', pageKey: props.match.url})
						return;

					}
				}
			}

			if (
				// No nav loaded
				!props.mainNavData ||
				// URL language differs from store language
				props.lang !== current_url_lang
			) {
	
				if (props.lang !== current_url_lang) {
					// Set new lang
					props.dispatch({type: 'SET_LANG', data: current_url_lang});		
				}
				// Fetch all menus
				// fetchGlobalData( props.dispatch );
				
			}
			
			if (!props.latestNews || props.latestNews.length === 0) {
				fetchLatestNews(props.dispatch);
			}

			// else fetch from api
			fetchData(props.dispatch, props.match, props.archiveQuery)
		}
	}, [props, prevUrl, receiveUrl, current_url_lang]);

	return null;
}

export default connect((state, ownProps) => ({
	lang: state.App.lang,
	latestNews: state.App.latestNews,
	archiveQuery: state.router.location.search,
	pageData: state.App.pageData,
	footerData: state.App.footerData,
	onArchiveAndShouldReload: state.Archive.selectedTermsAsArray.length > 0 && ownProps.match.url === '/archive' && state.router.location.search === ''
}))(ApiFetcher)