const initialState = {
	brandlogoIsMovedBack: false
}

const Sidebar = (state = initialState, action) => {
	switch(action.type) {
		case 'MOVE_BRANDLOGO_BACK':
			return {
				...state,
				brandlogoIsMovedBack: action.val
			}

		default:
			return state
	}
}

export default Sidebar