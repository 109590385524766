import React, {useRef, useState, useEffect} from 'react';
import {connect} from 'react-redux'
import {Link, useRouteMatch} from 'react-router-dom'
import {Animate} from 'react-move'
import { easeLinear, easeExpInOut } from 'd3-ease'
import VisibilitySensor from 'react-visibility-sensor'

import LinkWrapper from './../LinkWrapper'

function Thumb({data, dispatch, query, screenSize, isMobileSize, isMobile, keepAspectRatio}) {

    
    const [isLoaded, setIsLoaded] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [hasBeenInViewport, setHasBeenInViewport] = useState(false);

    const singlePeopleMatch = useRouteMatch("/people/:id");
    let personIsInFocus = singlePeopleMatch ? singlePeopleMatch.params.id === data.slug ? true : false : false
    // console.log(dataKey);

    const thumbRef = useRef();
    const videoRef = useRef();
    const imageRef = useRef();

    const getThumbOffsetY = () => {
        if (thumbRef.current) {

            const {offsetHeight} = imageRef.current;
            const thumb_distance_from_top = thumbRef.current.getBoundingClientRect().y;
            const image_height = offsetHeight;
            const available_height = window.innerHeight / 2 - thumb_distance_from_top - image_height / 2;
            return available_height;
        }
    }

    const getThumbOffsetX = () => {
        if (thumbRef.current) {
            const {offsetLeft, offsetWidth} = thumbRef.current;
            const currentCenterPosition = offsetLeft + offsetWidth/2;
            const newCenterPosition = (screenSize.width - 33)/4
            const calculatedOffset = newCenterPosition - currentCenterPosition;
            return calculatedOffset
        }
    }

    useEffect(() => {
        if (personIsInFocus) {
            playVideo();

            setTimeout(() => {
                const {offsetHeight} = imageRef.current;
                const newTopPosition = screenSize.height/2 - offsetHeight * 1.3 / 2 

                dispatch({
                    type: 'SET_SINGLE_PERSON_THUMB_OFFSET_TOP',
                    offset: newTopPosition
                })

            }, 200)
        } else {
            pauseVideo();
        }
    });

    const animationConfig = {
        desktop: {
            start: {
                xOffset: isMobileSize ? 0 : personIsInFocus ? [getThumbOffsetX()] : [0], 
                yOffset: isMobileSize ? 0 : personIsInFocus ? [getThumbOffsetY()] : [0],
                textOpacity: personIsInFocus ? [0] : [1],
                scale: isMobileSize ? 1 : personIsInFocus ? [1.3] : [1],
                zIndex: isMobileSize ? 1 : personIsInFocus ? 100 : [1],
                timing: {delay: isMobileSize ? 0 : personIsInFocus ? 0 : 150, duration: personIsInFocus ? 0 : 300, ease: easeExpInOut}
            },
            update: [{
                    xOffset: isMobileSize ? 0 : personIsInFocus ? [getThumbOffsetX()] : [0], 
                    yOffset: isMobileSize ? 0 : personIsInFocus ? [getThumbOffsetY()] : [0],
                    scale: isMobileSize ? 1 : personIsInFocus ? [1.3] : [1],
                    timing: {delay: isMobileSize ? 0 : personIsInFocus ? 0 : 50, duration: 300, ease: easeExpInOut}
                }, {
                    zIndex: isMobileSize ? 1 : personIsInFocus ? 100 : [1],
                    timing: {delay: isMobileSize ? 0 : personIsInFocus ? 0 : 300}
                }, {
                    textOpacity: personIsInFocus ? [0] : [1],
                    timing: {delay: personIsInFocus ? 0 : 400, duration: 300}
                }]   
        }
    }

    const playVideo = () => {
        if (!isMobile) videoRef.current.play()
    }

    const pauseVideo = () => {
        if (!isMobile) videoRef.current.pause()
    }

    let url = `/people/${data.slug}${window.location.search}`;

    let mediaClass = 'lazy-image-component';
    if ( !keepAspectRatio ) mediaClass += 'media media--portrait';
	return(
        <VisibilitySensor
            partialVisibility={true}
            active={hasBeenInViewport ? false : true}
            onChange={(isVisible) => {
                setIsVisible(isVisible);
                if (isVisible) setHasBeenInViewport(true)
            }}
        >
    		<div className="people__item">
                    <LinkWrapper>
                        <Link to={url}>
                                <Animate
                                    start={animationConfig.desktop.start}
                                    update={animationConfig.desktop.update}
                                >
                                {({xOffset, yOffset, scale, zIndex, textOpacity}) => {
                                    return(
                                        <div className={`inner ${isVisible || personIsInFocus ? 'visible' : 'hidden'}`} ref={thumbRef} style={{zIndex: zIndex, pointerEvents: personIsInFocus ? 'none' : 'auto'}}>
                                            <div className='viewport-visibility-component' style={{zIndex: zIndex, pointerEvents: personIsInFocus ? 'none' : 'auto'}}>
                                                <div
                                                    ref={imageRef}
                                                    className={mediaClass}
                                                    style={{transform: `translateX(${xOffset}px) translateY(${yOffset}px) scale(${scale})`}}
                                                >
                                                    <video ref={videoRef} className={`people-video ${isLoaded ? 'loaded' : 'lazy-loading'}`} muted loop onCanPlay={() => setIsLoaded(true)} onMouseEnter={() => personIsInFocus ? null : playVideo()} onMouseLeave={() => personIsInFocus ? null : pauseVideo()}>
                                                        <source src={`${data.video}#t=0.1`} type="video/mp4"/>
                                                    </video>
                                                </div>
                                            
                                            <div className="people__item__name" style={{opacity: textOpacity}}>{data.title}</div>
                                            <ul className="people__item__data" style={{opacity: textOpacity}}>
                                                {data.profession ? <li>{data.profession}</li> : null}
                                                {data.work_title ? <li>{data.work_title}</li> : null}
                                                {/* {data.phone ? <li>{data.phone}</li> : null}
                                                {data.email ? <li>{data.email}</li> : null} */}
                                            </ul>
                                        </div>
                                        </div>
                                        )
                                    }
                                }
                                </Animate>
                        </Link>
                    </LinkWrapper>

            </div>
        </VisibilitySensor> 
    )
}

export default connect((state) => ({
    scrollPosition: state.App.scrollPosition,
    screenSize: state.Device.size,
    isMobileSize: state.Device.isMobileSize,
    isMobile: state.Device.isMobile
}))(Thumb)