export const rodeoData = {
  size: {
    portrait: 1,
    landscape: 0.9
  },
  segments: [
  {
    "point": {
      "x": 782.3165421275296,
      "y": 710.6062648548668
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": -57.60145562790706,
      "y": -24.644069209302415
    }
  },
  {
    "point": {
      "x": 648.3530042670643,
      "y": 638.4899872734713
    },
    "handleIn": {
      "x": 31.707567627906997,
      "y": 23.433448186046462
    },
    "handleOut": {
      "x": -77.54464803720919,
      "y": -57.30930306976745
    }
  },
  {
    "point": {
      "x": 522.261844453112,
      "y": 468.741147087425
    },
    "handleIn": {
      "x": 15.899779869767485,
      "y": 54.25344893023254
    },
    "handleOut": {
      "x": -16.146511479069773,
      "y": -55.09534719999999
    }
  },
  {
    "point": {
      "x": 531.6990537554375,
      "y": 327.88068345951854
    },
    "handleIn": {
      "x": -14.252216855813913,
      "y": 28.896557246511634
    },
    "handleOut": {
      "x": 28.316279069767575,
      "y": -57.4116277581395
    }
  },
  {
    "point": {
      "x": 644.3339382205527,
      "y": 256.4112860269604
    },
    "handleIn": {
      "x": -42.96046586046509,
      "y": 0
    },
    "handleOut": {
      "x": 25.80930083720932,
      "y": 0
    }
  },
  {
    "point": {
      "x": 710.4060290112504,
      "y": 266.2551022130069
    },
    "handleIn": {
      "x": -21.823252837209225,
      "y": -10.848467348837204
    },
    "handleOut": {
      "x": 18.305494325581435,
      "y": 7.398056186046517
    }
  },
  {
    "point": {
      "x": 756.4804505926459,
      "y": 284.5446370223093
    },
    "handleIn": {
      "x": -13.919767813953513,
      "y": -5.548837209302349
    },
    "handleOut": {
      "x": 13.9197648372093,
      "y": 5.548837209302292
    }
  },
  {
    "point": {
      "x": 806.4164952438086,
      "y": 292.41556718044876
    },
    "handleIn": {
      "x": -21.07790586046508,
      "y": 0.7686045767442238
    },
    "handleOut": {
      "x": 12.423254325581297,
      "y": 0
    }
  },
  {
    "point": {
      "x": 879.2374247321807,
      "y": 273.96789283626276
    },
    "handleIn": {
      "x": -30.799999999999955,
      "y": 14.766279144186
    },
    "handleOut": {
      "x": 27.982559255813953,
      "y": -15.054651237209328
    }
  },
  {
    "point": {
      "x": 931.7223095693898,
      "y": 230.11807880835568
    },
    "handleIn": {
      "x": -13.413465302325562,
      "y": 16.142766139534842
    },
    "handleOut": {
      "x": 11.205253953488295,
      "y": -14.82192431627908
    }
  },
  {
    "point": {
      "x": 945.4936926391574,
      "y": 186.69508822230915
    },
    "handleIn": {
      "x": 1.9331780465115571,
      "y": 12.064995423255823
    },
    "handleOut": {
      "x": -2.045079813953521,
      "y": -9.660607553488376
    }
  },
  {
    "point": {
      "x": 925.3688211972969,
      "y": 163.98300904091383
    },
    "handleIn": {
      "x": 11.292764279069843,
      "y": 5.430652993488366
    },
    "handleOut": {
      "x": -23.009018046511642,
      "y": -12.166102980167437
    }
  },
  {
    "point": {
      "x": 819.8490533833435,
      "y": 179.4969625292859
    },
    "handleIn": {
      "x": 37.37335069767437,
      "y": -27.055126578604643
    },
    "handleOut": {
      "x": -60.776742697674536,
      "y": 46.18372093023254
    }
  },
  {
    "point": {
      "x": 698.0419374763667,
      "y": 366.5422648548674
    },
    "handleIn": {
      "x": 44.84579125581399,
      "y": -59.3599633860465
    },
    "handleOut": {
      "x": -30.664977860465,
      "y": 40.58958139534883
    }
  },
  {
    "point": {
      "x": 570.64207708567,
      "y": 447.74928818509943
    },
    "handleIn": {
      "x": 48.64206169302315,
      "y": -10.6749023255814
    },
    "handleOut": {
      "x": -48.380232632558204,
      "y": 10.617441041860502
    }
  },
  {
    "point": {
      "x": 458.1455653833442,
      "y": 443.8248695060297
    },
    "handleIn": {
      "x": 34.684883646511594,
      "y": 9.688528372093003
    },
    "handleOut": {
      "x": -82.92674411162795,
      "y": -25.167441860465146
    }
  },
  {
    "point": {
      "x": 319.10835608101866,
      "y": 292.0045904362628
    },
    "handleIn": {
      "x": -1.7081395200000316,
      "y": 56.830744111627894
    },
    "handleOut": {
      "x": -0.09767441860464032,
      "y": -33.4390233302326
    }
  },
  {
    "point": {
      "x": 427.1397515042744,
      "y": 190.12138120835567
    },
    "handleIn": {
      "x": -75.55930225116276,
      "y": 13.25581395348837
    },
    "handleOut": {
      "x": 65.62205231627905,
      "y": -11.51246368744188
    }
  },
  {
    "point": {
      "x": 621.2525406391583,
      "y": 227.5748694316115
    },
    "handleIn": {
      "x": -59.76511464186035,
      "y": -26.006976595348846
    },
    "handleOut": {
      "x": 34.048062511627904,
      "y": 14.864341134883745
    }
  },
  {
    "point": {
      "x": 710.4060290112504,
      "y": 266.2551022130069
    },
    "handleIn": {
      "x": -25.38759441860452,
      "y": -10.92248081860464
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  }
]
}