import React from 'react';
import {connect} from 'react-redux'
import NodeGroup from 'react-move/NodeGroup'

import './index.scss';

function CurrentSingleName(props) {
 
	if ( !props ) return <></>;

  return (
		<NodeGroup
			data={props.currentSingleName}
			keyAccessor={(d) => d}
			start={() => ({opacity: 0, offset: 6})}
			enter={() => ({opacity: [1], offset: [0], timing: {duration: 150}})}
			leave={() => ({opacity: [0], offset: [-6], timing: {duration: 150}})}
		>
			{(nodes) => (
				<div>
					{nodes.map(({key, data, state}) => (
						<div key={`currentSingleName${key}`} className={`currentSingleName ${props.menuIsShowing ? 'hide' : ''}`} style={{opacity: state.opacity, transform: `translateY(${state.offset}px)`}} >{data}</div>
					))}
				</div>
			)}
  	</NodeGroup>
  );
}

export default connect(state => ({
	currentSingleName: state.App.currentSingleName,
	menuIsShowing: state.App.menuIsShowing
}))(CurrentSingleName);