import React from 'react';
import './index.scss';

import LinkWrapper from '../LinkWrapper';
import {NavLink} from 'react-router-dom'

function Footer() {
  return (
	    <div className="footer l-columns-container l-columns-container--outmost">
				
				<div className="footer__column">
					<p className="footer__text">© RODEO ARCHITECTS {new Date().getFullYear()}</p>
				</div>

				<div className="footer__column">
					<LinkWrapper>
						<NavLink to={`/privacy`} className="footer__link">Privacy</NavLink>
					</LinkWrapper>
				</div>

				<div className="footer__column">
					<a href="https://www.instagram.com/rodeoarkitekter" rel="noreferrer" targer="_blank" className="footer__link">IG</a>
					<a href="https://www.facebook.com/rodeoarkitekter" rel="noreferrer" targer="_blank" className="footer__link">FB</a>
					<a href="https://www.linkedin.com/company/rodeo-architects" rel="noreferrer" targer="_blank" className="footer__link">LI</a>
				</div>
				
	    </div>
  );
}

export default Footer;
