import React, {useState} from 'react';
import { useRouteMatch } from 'react-router-dom';
import VisibilitySensor from 'react-visibility-sensor'
import {playPauseVideo} from './../../helpers/helpers.js'

import './index.scss';

function ScrollVisibilityComponent({children, callback, vidId, keepVisible}) {

	const [isVisible, setIsVisible] = useState(false);
	const [hasBeenInViewport, setHasBeenInViewport] = useState(false);
	const enHome = useRouteMatch('/');
	const noHome = useRouteMatch('/no');

	let component = (
		<VisibilitySensor
			partialVisibility={true} /*active={hasBeenInViewport ? false : true}*/
			onChange={(isVisible) => {
				setIsVisible(isVisible);
				if (hasBeenInViewport) setIsVisible(true);
				if (isVisible) setHasBeenInViewport(true);
				if (callback === 'video') playPauseVideo(isVisible, vidId);
			}}
		>
  		<div className={`viewport-visibility-component ${isVisible ? 'visible' : 'hidden'}`}>
    		{children}
    	</div>
    </VisibilitySensor>	
	);

	if ( (enHome && enHome.isExact) || (noHome && noHome.isExact) ) {
	
		component = (
			<VisibilitySensor
				offset={{
					top: keepVisible ? 84 : 84,
					bottom: window.innerHeight - 84
				}}	
				partialVisibility={true} onChange={(isVisible) => {
				setIsVisible(isVisible);
				if (callback === 'video') playPauseVideo(isVisible, vidId);
			}}>
				<div className={`viewport-visibility-component ${isVisible ? 'visible' : 'hidden'}`}>
					{children}
				</div>
			</VisibilitySensor>	
		);
	}

  return component;
}

export default ScrollVisibilityComponent;
