import React, {useState, useEffect, useRef} from 'react';

import './LassoSlide.scss';

import {Lasso} from './Lasso'

const LassoSlideContainer = ({type, sectionVal, color}) => {

	// const lasso = new Lasso();
	// lasso.init();
	
	const id = `lasso_${sectionVal}_${type}`;
	const resizeRef = useRef();
	const [lasso] = useState(new Lasso(type, id, color));

	// function setCurrentLassoName() {
	// 	var currentLassoName = lasso.actions.getCurrentLassoName();
	// 	props.dispatch({
	// 		type: 'SET_CURRENT_LASSO_NAME',
	// 		name: currentLassoName
	// 	})
	// }
	useEffect(() => {
		lasso.init();

		// props.dispatch({
		// 	type: 'SET_LASSONAMES',
		// 	lassoNames: lasso.actions.getAllLassoNames()
		// })

		// return () => {
		// 	lasso.actions.destroy()
		// }
	}, [lasso]);

	// useEffect(() => {
	// 	// console.log('ON LASSOSLIDE: ', props.onLassoSlide);

	// 	if (props.onLassoSlide) lasso.actions.startLassoCarousel();
	// 	else lasso.actions.stopLassoCarousel();

	// }, [props.onLassoSlide])

	// useEffect(() => {
	// 	// console.log('YES : it works');
	// }, [lasso.lassoNames[lasso.currentLassoName]])

	useEffect(() => {
		if (resizeRef.current !== undefined) {
			lasso.actions.destroy();
			lasso.init();
		}
		resizeRef.current = true;
	}, [lasso])

	return (
		<div className="frontpage-slide lasso">
			<canvas id={id} className="lasso__canvas" resize="true" keepalive="true"/>
		</div>
		)
}

export default LassoSlideContainer;

// export default connect((state) => ({
// 	type: s,
// 	screenSize: state.Device.size,
// 	onLassoSlide: state.Lasso.onLassoSlide
// }))(LassoSlideContainer)