const initialState = {
	currentLassoName: null,
	allLassoNames: [],
	lassoCarousselIsRunning: false,
	onLassoSlide: false
}

const Lasso = (state = initialState, action) => {
	switch(action.type) {
		case 'SET_CURRENT_LASSO_NAME':
			return {
				...state,
				currentLassoName: action.name
			}

		case 'SET_LASSONAMES':
			return {
				...state,
				allLassoNames: action.lassoNames
			}

		case 'LASSO_CAROUSSEL_IS_RUNNING':
			return {
				...state,
				lassoCarousselIsRunning: action.val
			}

		case 'SET_IS_ON_LASSOSLIDE':
			return {
				...state,
				onLassoSlide: action.val
			}

		default:
			return state
	}
}

export default Lasso