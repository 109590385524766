import React from 'react';
import {store} from './../config/configureStore.js';
import parse, {domToReact} from 'html-react-parser';
import LinkWrapper from './../components/LinkWrapper';
import Player from '@vimeo/player';
import Arrow from '../../assets/icons/arrow.js';

export function getApiBase() {
  const state = store.getState();
  const lang = state.App.lang;

  const dev_path = 'https://rodeo:8890';
  // 'http://dev.rodeo-backend';
  // 'http://w446863-www.php5.dittdomene.no/api';
	// 'http://rodeo.troelsknud.dk/api';
  // 'https://rodeo-arkitekter.no/api/',
  
  // const base_path = process.env.NODE_ENV === 'production' ? 'https://rodeo-arkitekter.no/api/' : dev_path; 
  const base_path = process.env.NODE_ENV === 'production' ? 'https://dev.rodeo-arkitekter.no/api' : dev_path; 

  if ( lang !== 'en' ) return base_path + '/' + lang;
	return base_path;
	
}

const mobileBreakpoint = 1200;

export const isMobile = () => {
  return store.getState().Device.size.width <= mobileBreakpoint ? true : false;
}

export const generateLink = (tax, term, isSelected, selectedTerms, selectedTermsAsArray) => {
    
    // Start query
    let query = '';
    let taxAllreadyExists = false;

    let i = 0;

    // Loop over taxonomies
    selectedTermsAsArray.forEach((ele) => {

      // If not the first taxonomy add "&" to query to seperate the taxonomies in the query
      let queryAppend = i !== 0 ? '&' : '';

      if (ele.tax === tax) {
        taxAllreadyExists = true;

        if (isSelected) {
          let newTermsForTax = ele.terms.filter(currentTerm => currentTerm !== term);
          query += newTermsForTax.length > 0 ? queryAppend + ele.tax + '=' + newTermsForTax : ''
        } else {
          query += queryAppend + ele.tax + '=' + selectedTerms[ele.tax] + ',' + term;
        }
      } else {
        query += queryAppend + ele.tax + '=' + selectedTerms[ele.tax];
      }

      i++;
    })

    if (!taxAllreadyExists) {
      query += selectedTermsAsArray.length > 0 ? '&' : '';
      query += tax + '=' + term;
    }

    // Clean up after removing last term in first applied taxonomy
    if (query.indexOf('&') === 0) {
      query = query.replace('&', '');
    }

    return query;
  }

export const getPageLink = (pageObj) => {
  let prefix;

  switch(pageObj.post_type) {
      case 'projects':
          prefix = '/projects';
      break;

      case 'people':
          prefix = '/people';
      break;

      case 'page':
          prefix = '';
      break;

      default:
        prefix = '';

  }

  const slug = pageObj.post_type === 'page' && pageObj.post_name === 'frontpage' ? '/' : '/'+pageObj.post_name;
  
  return prefix + slug

}

export const convertHTMLStringToReact = (htmlString, linkHoverImages) => {
    let i = 0;

    let sizeKeys;
    if (linkHoverImages && linkHoverImages[0].sizes) {

      sizeKeys = Object.keys(linkHoverImages[0].sizes).filter(key => {
        
        return ( key.indexOf("-width") === -1 && key.indexOf("-height") === -1 ) ? key : null;
  
      });

    }

    const options = {
      replace: ({attribs, children}) => {
        if (!attribs) return;

        if (attribs.href) {
          if (linkHoverImages && sizeKeys) {
            
            const image = linkHoverImages[i];
            const srcset = sizeKeys.map(key => `${image.sizes[key]} ${image.sizes[key + "-width"]}w`).join(",");
		        const imageElement = <img src={image.sizes.large} srcSet={srcset} sizes="50vw" alt={image.alt}/>;
            
            i++;
            return (
              <LinkWrapper>
                <a href={attribs.href.toLowerCase()} target={attribs.target}>
                  <span className="linkwrapper__arrow">
                    <Arrow />
                  </span>
                  <span className="linkwrapper__text">{domToReact(children, options)}</span>
                  {imageElement}
                </a>
              </LinkWrapper>
            );
          }
          return <LinkWrapper noLinkSpan><a href={attribs.href.toLowerCase()} target={attribs.target}>{domToReact(children, options)}</a></LinkWrapper>
        }
      }
    }
    if ( htmlString ) return parse(htmlString, options);
  return '';
}

export const playPauseVideo = (state, id) => {
    var iFrame = document.getElementById(id);
    var player = new Player(iFrame);
    if (state) player.play();
    else player.pause()
  }