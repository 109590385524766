import React, {useEffect} from 'react';
import { Link, useRouteMatch} from 'react-router-dom'
import {connect} from 'react-redux';
import {Animate} from 'react-move'
import _ from 'lodash'
import BezierEasing from 'bezier-easing'
import LinkWrapper from './../LinkWrapper'
import {generateLink} from './../../helpers/helpers';

import {moveBrandlogoBack} from './../../actions'

import './index.scss';


function PeopleSingle(props) {

	// let	match = useRouteMatch(`/people/${props.personData.slug}`);
	let	singlePeopleMatch = useRouteMatch();
	let match = singlePeopleMatch && singlePeopleMatch.isExact;
	
	// if ( props.personData.slug == '' ) match = false;
	const easingBezier = BezierEasing(0.9, 0, 0.1, 1);
	
	useEffect(() => {
		props.dispatch({
			type: 'SET_SCROLL_ON_OFF',
			val: match ? false : true
		})

		moveBrandlogoBack(match ? true : false);

	}, [match])

	useEffect(() => {

		if (props.sidebarIsOpen) {

			props.dispatch({
				type: 'CLOSE_FILTER'
			});
			
			// moveBrandlogoBack(match ? true : false);

		}

		// // Clean up store when there's no match
		// if ( !match && props.personData.slug !== null && props.personData.slug !== '' ) {
		
		// 	props.dispatch({
		// 		type: 'SET_SINGLE_PERSON',
		// 		singlePersonSlug: null,
		// 	});

		// }

	}, [match, props]);

	// match = props.singlePersonSlug === null ? false : match;

	let person = <></>;
	if ( props.personData ) {
		person = (
			<div className="inner">
				<h1 className="name">{props.personData.title}</h1>
				<ul className="data">
					<li>{props.personData.profession}</li>
					<li>{props.personData.work_title}</li>
				</ul>
				<ul className="contact">
					{props.personData.phone ? <li><LinkWrapper><a href={`tel:${props.personData.phone}`}>{props.personData.phone}</a></LinkWrapper></li> : null}
					{props.personData.email ? <li><LinkWrapper><a href={`mailto:${props.personData.email}`}>{props.personData.email}</a></LinkWrapper></li> : null}
				</ul>
				<div className="description" dangerouslySetInnerHTML={{__html: props.personData.description}}/>
			</div>
		);
	}
 
	return(
		<Animate
			show={match ? true : false}
			start={{backgroundOpacity: 0, xPos: 100, btnOpacity: 0}}
			enter={[{
					backgroundOpacity: props.isMobileSize ? [0] : [1],
					timing: {duration: 300, delay: 0}
				},{
					xPos: [0],
					timing: {delay: 50, duration: 600, ease: easingBezier}
				}, {
					btnOpacity: [1],
					timing: {duration: 300, delay: 500}
				}]}
			leave={[{
					backgroundOpacity: [0],
					timing: {delay: 400, duration: 300}
				},{
					xPos: [100],
					timing: {duration: 600, delay: 0, ease: easingBezier}
				},{
					btnOpacity: [0],
					timing: {duration: 300, delay: 0}
			}]}
		>
			{
				({backgroundOpacity, xPos, btnOpacity}) => {
					
					const closeUrl = `/people${window.location.search}`;
					
					return(
						<div className="people-single" style={{top: `${props.scrollPosition}px`, background: `rgba(255,255,255,${backgroundOpacity})`}}>
							<LinkWrapper className="people-single__close-btn__container">
								<Link to={closeUrl}>
									<div className="close-btn" style={{opacity: btnOpacity}}>
										<div className="inner"/>
									</div>
								</Link>
							</LinkWrapper>
							
							<div className="sidebar" style={{transform: `translateX(${xPos}%) translateZ(0)`}}>
								{person}
							</div>
						</div>
					)
				}
			}
		</Animate>
	)
}

export default connect((state, ownProps) => {

	const dataPeopleAll = state.App.pageData[ownProps.dataKey].data.people
	
	let dataPeopleCurrent = {slug: ""};
	if ( state.App.singlePersonSlug ) {
		dataPeopleCurrent = _.find(dataPeopleAll, (o) => {return o.slug === state.App.singlePersonSlug});
	}
	
	return ({
		sidebarIsOpen: state.Sidebar.sidebarIsOpen,
		scrollPosition: state.App.scrollPosition,
		personData: dataPeopleCurrent,
		singlePersonSlug: state.App.singlePersonSlug,
		singlePersonThumbOffsetsetTop: state.App.singlePersonThumbOffsetsetTop,
		isMobileSize: state.Device.isMobileSize
	})})(PeopleSingle)