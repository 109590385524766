export const studioData = {
  size: {
    portrait: 1,
    landscape: 0.9
  },
  segments: [
  {
    "point": {
      "x": 490.9023658675497,
      "y": 484.4195414799289
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": 12.243348837209282,
      "y": -20.801488372092933
    }
  },
  {
    "point": {
      "x": 549.225714704759,
      "y": 445.8052158985336
    },
    "handleIn": {
      "x": -23.930046511627893,
      "y": 3.1493953488372313
    },
    "handleOut": {
      "x": 23.930046511628007,
      "y": -3.14641860465116
    }
  },
  {
    "point": {
      "x": 615.5535286582474,
      "y": 468.0176810148127
    },
    "handleIn": {
      "x": -17.208558139534944,
      "y": -16.9257674418605
    },
    "handleOut": {
      "x": 16.01488372093013,
      "y": 15.749953488372114
    }
  },
  {
    "point": {
      "x": 635.7596681931311,
      "y": 529.3594484566731
    },
    "handleIn": {
      "x": 4.718139534883676,
      "y": -21.962418604651134
    },
    "handleOut": {
      "x": -4.182325581395389,
      "y": 19.47088372093026
    }
  },
  {
    "point": {
      "x": 600.616226332666,
      "y": 575.7371228752777
    },
    "handleIn": {
      "x": 16.803720930232544,
      "y": -10.692465116278981
    },
    "handleOut": {
      "x": -41.51069767441868,
      "y": 26.421581395348994
    }
  },
  {
    "point": {
      "x": 463.7633891233636,
      "y": 562.9103321776033
    },
    "handleIn": {
      "x": 36.59609302325589,
      "y": 32.893023255814
    },
    "handleOut": {
      "x": -36.596093023255776,
      "y": -32.890046511627816
    }
  },
  {
    "point": {
      "x": 430.47148214661945,
      "y": 427.65600659620804
    },
    "handleIn": {
      "x": -16.154790697674457,
      "y": 46.478883720930185
    },
    "handleOut": {
      "x": 16.151813953488386,
      "y": -46.47590697674423
    }
  },
  {
    "point": {
      "x": 538.1075751698752,
      "y": 336.64502985202193
    },
    "handleIn": {
      "x": -48.33041860465107,
      "y": 9.236837209302394
    },
    "handleOut": {
      "x": 39.206697674418706,
      "y": -7.495441860465064
    }
  },
  {
    "point": {
      "x": 651.6525054024335,
      "y": 362.06344845667314
    },
    "handleIn": {
      "x": -32.03274418604656,
      "y": -23.81395348837208
    },
    "handleOut": {
      "x": 32.0357209302324,
      "y": 23.81395348837208
    }
  },
  {
    "point": {
      "x": 706.4930635419682,
      "y": 464.12409961946383
    },
    "handleIn": {
      "x": -1.7830697674418161,
      "y": -39.876465116279064
    },
    "handleOut": {
      "x": 1.146046511627901,
      "y": 25.739906976744237
    }
  },
  {
    "point": {
      "x": 682.667203076852,
      "y": 535.4587972938824
    },
    "handleIn": {
      "x": 17.78009302325586,
      "y": -18.646325581395217
    },
    "handleOut": {
      "x": -17.78009302325586,
      "y": 18.649302325581516
    }
  },
  {
    "point": {
      "x": 612.079668193131,
      "y": 551.8427972938824
    },
    "handleIn": {
      "x": 23.456744186046535,
      "y": 10.659720930232538
    },
    "handleOut": {
      "x": -23.456744186046535,
      "y": -10.659720930232538
    }
  },
  {
    "point": {
      "x": 588.58124958848,
      "y": 487.32782054969636
    },
    "handleIn": {
      "x": -12.820837209302454,
      "y": 22.346418604651205
    },
    "handleOut": {
      "x": 9.695255813953395,
      "y": -16.895999999999958
    }
  },
  {
    "point": {
      "x": 636.5246914489451,
      "y": 456.24465775899876
    },
    "handleIn": {
      "x": -18.369488372093087,
      "y": 6.483348837209292
    },
    "handleOut": {
      "x": 23.293023255813978,
      "y": -8.224744186046564
    }
  },
  {
    "point": {
      "x": 706.4007844722009,
      "y": 431.5763786892313
    },
    "handleIn": {
      "x": -23.290046511627907,
      "y": 8.221767441860436
    },
    "handleOut": {
      "x": 28.398139534883626,
      "y": -10.025674418604694
    }
  },
  {
    "point": {
      "x": 794.3695286582474,
      "y": 421.0119135729522
    },
    "handleIn": {
      "x": -28.561860465116297,
      "y": -9.549395348837209
    },
    "handleOut": {
      "x": 24.445023255813794,
      "y": 8.174139534883693
    }
  },
  {
    "point": {
      "x": 845.3373426117357,
      "y": 475.5131228752778
    },
    "handleIn": {
      "x": -8.296186046511593,
      "y": -24.403348837209307
    },
    "handleOut": {
      "x": 8.299162790697665,
      "y": 24.403348837209307
    }
  },
  {
    "point": {
      "x": 848.7814356349916,
      "y": 551.9678205496964
    },
    "handleIn": {
      "x": 4.2716279069767324,
      "y": -25.41841860465115
    },
    "handleOut": {
      "x": -6.28390697674422,
      "y": 37.39683720930236
    }
  },
  {
    "point": {
      "x": 796.7419937745263,
      "y": 650.0247507822546
    },
    "handleIn": {
      "x": 30.29432558139547,
      "y": -22.804837209302377
    },
    "handleOut": {
      "x": -30.297302325581427,
      "y": 22.801860465116192
    }
  },
  {
    "point": {
      "x": 692.7405054024334,
      "y": 647.944006596208
    },
    "handleIn": {
      "x": 26.293581395348838,
      "y": 27.320558139534796
    },
    "handleOut": {
      "x": -27.67776744186051,
      "y": -28.764279069767554
    }
  },
  {
    "point": {
      "x": 699.6048774954565,
      "y": 539.8852158985337
    },
    "handleIn": {
      "x": -27.802790697674254,
      "y": 28.64223255813954
    },
    "handleOut": {
      "x": 27.80279069767448,
      "y": -28.642232558139654
    }
  },
  {
    "point": {
      "x": 809.8039472628984,
      "y": 502.7146112473708
    },
    "handleIn": {
      "x": -39.90027906976729,
      "y": -1.2204651162790014
    },
    "handleOut": {
      "x": 19.917395348837317,
      "y": 0.6102325581395576
    }
  },
  {
    "point": {
      "x": 866.6925054024334,
      "y": 518.0716345031848
    },
    "handleIn": {
      "x": -16.318511627907014,
      "y": -11.439627906976739
    },
    "handleOut": {
      "x": 27.56167441860464,
      "y": 19.325023255813903
    }
  },
  {
    "point": {
      "x": 890.592784472201,
      "y": 608.7581461310917
    },
    "handleIn": {
      "x": 11.92781395348834,
      "y": -31.479069767441842
    },
    "handleOut": {
      "x": -11.927813953488453,
      "y": 31.47609302325577
    }
  },
  {
    "point": {
      "x": 818.6002263326659,
      "y": 675.2288438055103
    },
    "handleIn": {
      "x": 31.470139534883856,
      "y": -11.945674418604654
    },
    "handleOut": {
      "x": -31.4731162790697,
      "y": 11.945674418604654
    }
  },
  {
    "point": {
      "x": 719.1591100535961,
      "y": 689.0439135729522
    },
    "handleIn": {
      "x": 33.661023255813916,
      "y": 0.2440930232558003
    },
    "handleOut": {
      "x": -64.64893023255809,
      "y": -0.46437209302325755
    }
  },
  {
    "point": {
      "x": 527.4627379605729,
      "y": 663.1968438055103
    },
    "handleIn": {
      "x": 62.461023255813984,
      "y": 16.675720930232615
    },
    "handleOut": {
      "x": -36.61097674418602,
      "y": -9.77265116279068
    }
  },
  {
    "point": {
      "x": 422.60692400708456,
      "y": 620.4656810148126
    },
    "handleIn": {
      "x": 32.00297674418607,
      "y": 20.286511627906975
    },
    "handleOut": {
      "x": -32.00297674418607,
      "y": -20.286511627906975
    }
  },
  {
    "point": {
      "x": 348.4651565652241,
      "y": 536.6792624101615
    },
    "handleIn": {
      "x": 14.434232558139513,
      "y": 35.03330232558142
    },
    "handleOut": {
      "x": -14.580093023255813,
      "y": -35.384558139534874
    }
  },
  {
    "point": {
      "x": 342.7230170303404,
      "y": 423.91423915434757
    },
    "handleIn": {
      "x": -9.079069767441865,
      "y": 37.176558139534905
    },
    "handleOut": {
      "x": 9.076093023255794,
      "y": -37.176558139534905
    }
  },
  {
    "point": {
      "x": 390.51762168150316,
      "y": 320.1240996194639
    },
    "handleIn": {
      "x": -20.78958139534882,
      "y": 32.130976744186
    },
    "handleOut": {
      "x": 29.02027906976747,
      "y": -44.85655813953491
    }
  },
  {
    "point": {
      "x": 501.4281333094101,
      "y": 206.4630763636499
    },
    "handleIn": {
      "x": -46.09786046511624,
      "y": 27.00502325581394
    },
    "handleOut": {
      "x": 46.094883720930284,
      "y": -27.00800000000001
    }
  },
  {
    "point": {
      "x": 655.3645054024335,
      "y": 186.9832624101615
    },
    "handleIn": {
      "x": -50.22958139534887,
      "y": -18.202790697674402
    },
    "handleOut": {
      "x": 44.53506976744177,
      "y": 16.136930232558143
    }
  },
  {
    "point": {
      "x": 759.1576216815031,
      "y": 280.09284380551037
    },
    "handleIn": {
      "x": -24.90939534883705,
      "y": -40.290232558139536
    },
    "handleOut": {
      "x": 16.64595348837213,
      "y": 26.924651162790724
    }
  },
  {
    "point": {
      "x": 793.4288774954566,
      "y": 367.90679729388245
    },
    "handleIn": {
      "x": -4.259720930232561,
      "y": -31.36893023255817
    },
    "handleOut": {
      "x": 4.25674418604649,
      "y": 31.3659534883721
    }
  },
  {
    "point": {
      "x": 773.868691448945,
      "y": 458.563541479929
    },
    "handleIn": {
      "x": 18.283162790697702,
      "y": -25.84111627906981
    },
    "handleOut": {
      "x": -18.286139534883773,
      "y": 25.84111627906975
    }
  },
  {
    "point": {
      "x": 692.1630170303404,
      "y": 497.2195414799289
    },
    "handleIn": {
      "x": 31.3986976744186,
      "y": 4.027534883720932
    },
    "handleOut": {
      "x": -43.186604651162725,
      "y": -5.539720930232534
    }
  },
  {
    "point": {
      "x": 617.6967844722008,
      "y": 403.7706112473708
    },
    "handleIn": {
      "x": -0.18753488372090032,
      "y": 43.54083720930231
    },
    "handleOut": {
      "x": 0.187534883721014,
      "y": -43.54083720930231
    }
  },
  {
    "point": {
      "x": 678.6039472628985,
      "y": 293.2887507822545
    },
    "handleIn": {
      "x": -34.432000000000016,
      "y": 26.650790697674438
    },
    "handleOut": {
      "x": 34.43497674418609,
      "y": -26.647813953488367
    }
  },
  {
    "point": {
      "x": 796.6735286582474,
      "y": 239.22214613109173
    },
    "handleIn": {
      "x": -41.719069767441965,
      "y": 12.466604651162811
    },
    "handleOut": {
      "x": 23.90623255813955,
      "y": -7.147162790697649
    }
  },
  {
    "point": {
      "x": 870.2020867977823,
      "y": 228.4403786892313
    },
    "handleIn": {
      "x": -24.784372093023194,
      "y": -2.896372093023274
    },
    "handleOut": {
      "x": 24.781395348837236,
      "y": 2.8993488372092884
    }
  },
  {
    "point": {
      "x": 928.42124958848,
      "y": 269.189029852022
    },
    "handleIn": {
      "x": -8.477767441860465,
      "y": -23.465674418604635
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  }
]}