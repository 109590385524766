import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import App from './js/components/App.js';
import * as serviceWorker from './serviceWorker';

import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router'


import {store, history} from './js/config/configureStore'
import {InitDeviceListener} from './js/config/initDeviceAndScreen.js';

import {InitAnalytics} from './js/config/initAnalytics.js';
// import {initScroll} from './js/config/initScroll.js';


// import { polyfill } from 'es6-promise'; 
// polyfill();

InitAnalytics(history);
InitDeviceListener(store)
// initScroll(store);

ReactDOM.render(
	<Provider store={store}>
		<ConnectedRouter history={history}>
			<App />
		</ConnectedRouter>
	</Provider>, 
	document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
