import {setDeviceToMobile, setDeviceSize} from './../actions/'
import MobileDetect from 'mobile-detect';

export const InitDeviceListener = (store) => {
	_checkForDevicetype(store);
	window.addEventListener('resize', () => _checkForDevicetype(store));
}

const _checkForDevicetype = (store) => {
	let md = new MobileDetect(window.navigator.userAgent);
	store.dispatch(setDeviceToMobile(md.mobile()))
	store.dispatch(setDeviceSize(window.innerWidth, window.innerHeight))
}