const initialState = {
	lang: 'en',
	latestNews: [],
	pageIsLoading: false,
	pageData: null,
	mainNavData: null,
	footerNavData: null,
	soMeNavData: null,
	langNavData: null,
	pagesToShow: [],
	singlePersonSlug: null,
	scrollPosition: 0,
	scrollDest: null,
	scrollIsAnimating: false,
	menuIsShowing: false,
	currentMenuName: [],
	scrollIsActive: true,
	singlePersonThumbOffsetsetTop: null,
	currentSingleName: [],
}

const App = (state = initialState, action) => {
	switch(action.type) {

		case 'PAGE_HAS_LOADED':
			// let currentPageKey = Math.random().toString(36).substr(2,9)
			const currentPageKey = action.pageObj.url;

			return {
				...state,
				pageIsLoading: false,
				pagesToShow: [{key: currentPageKey, component: action.pageObj.component}],
				pageData: {
					...state.pageData,
					[currentPageKey]: {
						component: action.pageObj.component,
						menuName: action.pageObj.menuname,
						data: action.pageObj.data
					}
				},
				menuIsShowing: false,
				currentMenuName: [action.pageObj.menuname],
				scrollPosition: 0,
				currentSingleName: []
			}

		case 'SET_LANG':
			return {
				...state,
				lang: action.data,
			};

		case 'SET_LATEST_NEWS':
			return {
				...state,
				latestNews: action.data,
			};

		case 'SET_MAIN_NAV_DATA':
			return {
				...state,
				mainNavData: action.data,
			};

		case 'SET_FOOTER_NAV_DATA':
			return {
				...state,
				footerNavData: action.data,
			};

		case 'SET_SOME_NAV_DATA':
			return {
				...state,
				soMeNavData: action.data,
			};

		case 'SET_LANG_NAV_DATA':
			return {
				...state,
				langNavData: action.data,
			};

		case 'SET_CURRENT_PAGE':
			const component = state.pageData[action.pageKey].component;
			const menuName = state.pageData[action.pageKey].menuName;

			return {
				...state,
				pagesToShow: [{key: action.pageKey, component: component}],
				pageIsLoading: false,
				menuIsShowing: false,
				currentMenuName: [menuName],
				scrollPosition: 0,
				currentSingleName: []
			}

		case 'PAGE_TRANSITION_COMPLETE':
			const keyOfCurrentPage = state.pagesToShow[0].key;

			return {
				...state,
				pageData: {[keyOfCurrentPage]: state.pageData[keyOfCurrentPage]}
			}

		case 'PAGE_IS_LOADING':
			return {
				...state,
				pageIsLoading: true,
				menuIsShowing: false
			}

		case 'SET_SINGLE_PERSON':
			return {
				...state,
				singlePersonSlug: action.slug
			}

		case 'SET_SCROLL_POSITION':
			return {
				...state,
				scrollPosition: action.scrollPosition
			}

		case 'SET_MENU_IS_SHOWING':
			return {
				...state,
				menuIsShowing: action.val
			}

		case 'TOGGLE_MENU_IS_SHOWING':
			return {
				...state,
				menuIsShowing: !state.menuIsShowing
			}

		case 'UPDATE_ARCHIVE_POSTS':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.dataKey]: {
						...state.pageData[action.dataKey],
						data: {
							...state.pageData[action.dataKey].data,
							posts: action.posts
						}
					}
				}
			}

		case 'UPDATE_PEOPLE_POSTS':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.dataKey]: {
						...state.pageData[action.dataKey],
						data: {
							...state.pageData[action.dataKey].data,
							people: action.people
						}
					}
				}
			}

		case 'SET_SCROLL_ON_OFF':
			return {
				...state,
				scrollIsActive: action.val
			}

		case 'SET_SINGLE_PERSON_THUMB_OFFSET_TOP':
			return {
				...state,
				singlePersonThumbOffsetsetTop: action.offset
			}

		case 'SHOW_SINGLE_NAME':
			const pageKey = state.pagesToShow[0].key

			return {
				...state,
				currentSingleName: [state.pageData[pageKey].title]
			}

		case 'HIDE_SINGLE_NAME':
			return {
				...state,
				currentSingleName: []
			}

		case 'SET_SCROLL_DEST':
			return {
				...state,
				scrollDest: action.scrollDest,
				scrollIsAnimating: true,
			}

		case 'RESET_ANIMATED_SCROLL':
			return {
				...state,
				scrollIsAnimating: false,
				scrollDest: state.scrollPosition,
			}

		default:
			return state
	}
}

export default App