import React, { useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import LazyImageComponent from '../LazyImageComponent';
import ScrollVisibilityComponent from '../ScrollVisibilityComponent';
// import LinkWrapper from '../LinkWrapper';
// import { Link } from 'react-router-dom';
import {store} from '../../config/configureStore.js';
import {getApiBase} from './../../helpers/helpers'

import './index.scss';
import TitleText from './TitleText';

import 'swiper/css';
import 'swiper/css/navigation';
// import SimpleLasso from './../SimpleLasso'
import LassoSlideContainer from '../LassoSlide/LassoSlide';
import LinkWrapper from '../LinkWrapper';
import { Link } from 'react-router-dom';

// const ImageWithLink = (link, width, height, url) => {
// 	return (
// 		link ?
// 			<ScrollVisibilityComponent>
// 				<LinkWrapper>
// 					<Link to={`/projects/${link}`}>
// 						<LazyImageComponent width={width} height={height} url={url} />
// 					</Link>
// 				</LinkWrapper>
// 			</ScrollVisibilityComponent>
// 			:
// 			<ScrollVisibilityComponent>
// 				<LazyImageComponent width={width} height={height} url={url} />
// 			</ScrollVisibilityComponent>
// 	)
// }

function Thumb(props) {
	const [isLoaded, setIsLoaded] = useState(false);
	const videoRef = useRef();

	const videoCanPlay = () => {
		setIsLoaded(true);
		if (!store.getState().Device.isMobile) videoRef.current.play();
	}

	return(
		<div className={`lazy-image-component ${isLoaded ? 'loaded' : 'loading'} ${props.className ? props.className : null}`}>
			{props.videoUrl ? 
				<video ref={videoRef} className={`people-video`} muted loop onCanPlay={() => videoCanPlay()}>
					<source src={`${props.videoUrl}#t=0.1`} type="video/mp4"/>
				</video>
			: null}
		</div>
	)
}


export const Border = () => {
	return <div className="section-border"></div>
};

export const Headline = ({ data }) => {

	const headline = data.headline_headline;
	return (
		<div className="page-section headline">
			<ScrollVisibilityComponent>
				<div className="l-columns-container--outmost">
					<div className="headline__headline f-section-headline">{headline}</div>
				</div>
			</ScrollVisibilityComponent>
		</div>
	);

}

export const HeadlineImageText = ({ data }) => {

	const headline = data.headline_image_and_text_headline;
	const image = data.headline_image_and_text_image;
	const text = data.headline_image_and_text_text;

	return (
		<div className="page-section headline-image-text">
			<ScrollVisibilityComponent>
				<div className="l-columns-container l-columns-container--outmost">
					<div className="headline-image-text__headline f-section-headline">{headline}</div>

					<div className="headline-image-text__content">
						<LazyImageComponent image={image} sizes="(min-width: 700px) 50vw, 100vw" />
						<div className="headline-image-text__text" dangerouslySetInnerHTML={{ __html: text }}></div>
					</div>
				</div>
			</ScrollVisibilityComponent>
		</div>
	);

}

export const HeadlineText = ({ data }) => {

	const headline = data.headline_and_text_headline;
	const text = data.headline_and_text_text;

	return (
		<div className="page-section headline-text">
			<ScrollVisibilityComponent>
				<div className="l-columns-container l-columns-container--outmost">
					<div className="headline-text__headline f-section-headline">{headline}</div>
					<div className="headline-text__text" dangerouslySetInnerHTML={{ __html: text }}></div>
				</div>
			</ScrollVisibilityComponent>
		</div>
	);

}


export const ID = ({data}) => {

	const id = data.id;
	return (
		<div id={id} className="id" />
	);

}


export const ImageLarge = ({ data }) => {

	const image = data.image_large_image_large;

	let title_text = <></>;
	if ( data.text_title || data.text_link ) {
		title_text = <TitleText title={data.text_title} link={data.text_link} color={data.text_text_color} />;
	}

	return (
		<>
			<ScrollVisibilityComponent>
				<div className="page-section image-large">
					<div className="page-section__inner">
							<div className="l-columns-container l-columns-container--outmost">
								<LazyImageComponent image={image} sizes="(min-width: 700px) 75vw, 100vw" />
							</div>
					</div>
				</div>
			</ScrollVisibilityComponent>
			{title_text}
		</>
	);

}

export const ImageFull = ({ data, keepVisible }) => {

	const image = data.image_full_image;

	let title_text = <></>;
	if ( data.text_title || data.text_link ) {
		title_text = <TitleText title={data.text_title} link={data.text_link} color={data.text_text_color} className={keepVisible ? 'keepVisible' : ''}/>;
	}
	
	return (
		<>
			<ScrollVisibilityComponent keepVisible={keepVisible}>
				<div className="page-section image-full">
						<LazyImageComponent image={image} sizes="(min-width: 700px) 75vw, 100vw" />
				</div>
			</ScrollVisibilityComponent>
			{title_text}
		</>
	);

}


export const LargeText = ({ data }) => {
	return (
		<div className="page-section large-text l-columns-container--outmost">
			<ScrollVisibilityComponent>
				<div className="large-text__text f-section-headline" dangerouslySetInnerHTML={{__html: data.large_text_text}}></div>
			</ScrollVisibilityComponent>
		</div>
	);

}

export const Lasso = ({data, sectionVal}) => {

	const color = data.lasso_title_text_color;

	let title_text = <></>;
	if ( data.lasso_title_title || data.lasso_title_link ) {
		title_text = <TitleText title={data.lasso_title_title} link={data.lasso_title_link} color={data.lasso_title_text_color} />;
	}

	return (
		<>
			<ScrollVisibilityComponent>
				<div className={`page-section lasso ${color}`}>
					<LassoSlideContainer type={data.lasso_type} sectionVal={sectionVal} color={color} />
				</div>
			</ScrollVisibilityComponent>
			{title_text}
		</>
	);
}


export const LatestNews = ({ data, lang, last }) => {

	const [height, setHeight] = useState(0);
	const ref = useRef(null);

	useEffect(() => {
		if (ref && ref.current) {
			setHeight(ref.current.clientHeight);
		}
  });
	
	window.addEventListener('resize', () => {
		if (ref && ref.current) {
			setHeight(ref.current.clientHeight);	
		}
	});

	const items = data.map( (item, i) => {

		let image = i % 2 === 0 ? item.featured_portrait : item.featured_landscape;
		let imageElement = image ? <div className="latest-news__item__image-container" dangerouslySetInnerHTML={{__html: image}}></div> : <div className="latest-news__item__image-container" />;

		return (			
			<LinkWrapper key={item.ID} className="latest-news__item">
				<Link to={`/post/${item.ID}`}>
					{imageElement}
					<p>{item.post_title}</p>
				</Link>
			</LinkWrapper>
		);
	});

	if ( last ) {
	
		return (
			<div className="page-section latest-news l-columns-container--outmost" style={{height: height > 0 ? height : 'auto'}}>
				<div className="latest-news__inner" ref={ref}>
					<h2 className="f-section-headline">{lang === 'en' ? "News" : "Nyheter"}</h2>
	
					<div className="latest-news__items l-columns-container">
						{items}
					</div>
				</div>
			</div>
		);

	}

	return (
		<div className="page-section latest-news l-columns-container--outmost">
			<ScrollVisibilityComponent>
				<h2 className="f-section-headline">{lang === 'en' ? "News" : "Nyheter"}</h2>

				<div className="latest-news__items l-columns-container">
					{items}
				</div>
			</ScrollVisibilityComponent>

		</div>
	);

}


export const People = ({data}) => {

	const [apiData, setApiData] = useState(null); 

  useEffect(() => {
    const loadPeopleFromAPI = async () => {
      const response = await fetch(getApiBase()+'/wp-json/data/v2/people');
      const jsonResponse = await response.json();
      let peopleNum = [], selectedPeople = [];
      for (let i = 0; i < 3; i++) {
        let numIsUnique = false, num;
        while(!numIsUnique) {
          num = Math.floor(Math.random() * jsonResponse.people.length);
          numIsUnique = !peopleNum.includes(num);
        }
          peopleNum.push(num);
          selectedPeople.push(jsonResponse.people[num])
      }

      setApiData(selectedPeople);
    }

    loadPeopleFromAPI();
  }, [])

	let title_text = <></>;
	if ( data.text_title || data.text_link ) {
		title_text = <TitleText title={data.text_title} link={data.text_link} color={data.text_text_color} />;
	}

  return(
		<>
			<ScrollVisibilityComponent>
				<div className="page-section people">
						<div className="page-section__inner">
								<div className="l-columns-container l-columns-container--outmost three-columns">
									<Thumb videoUrl={apiData ? apiData[0].video : null} className="three-columns__first media media--portrait"/>
									<Thumb videoUrl={apiData ? apiData[1].video : null} className="three-columns__second media media--portrait"/>
									<Thumb videoUrl={apiData ? apiData[2].video : null} className="three-columns__third media media--portrait"/>
								</div>
						</div>
					</div>
				</ScrollVisibilityComponent>
				{title_text}
			</>
    );

}

export const Slideshow = ({ data, sectionVal }) => {
	
	const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)

	const slides = data.slideshow_slides.map((slide, i) => {
		
		let slideInner = <></>;
		
		if (slide.acf_fc_layout === 'image_large' ) {
			slideInner = <ImageLarge data={slide}/>;		
		} else if (slide.acf_fc_layout === 'image_full' ) {
			slideInner = <ImageFull data={slide}/>;
		} else if (slide.acf_fc_layout === 'lasso' ) {
			slideInner = <Lasso data={slide} sectionVal={`${sectionVal}_${i}`}/>;
		} else if (slide.acf_fc_layout === 'people' ) {
			slideInner = <People data={slide}/>;
		} else if (slide.acf_fc_layout === 'text' ) {
			slideInner = <Text data={slide}/>;
		} else if (slide.acf_fc_layout === 'two_images' ) {
			slideInner = <TwoImages data={slide} />
		} else if (slide.acf_fc_layout === 'video' ) {
			slideInner = <Video data={slide} />
		} else {
			console.log(slide.acf_fc_layout);
			slideInner = <></>;
		}
			
		return (
			<SwiperSlide key={`slide_${sectionVal}_${i}`}>
				{slideInner}
			</SwiperSlide>
		);
	});

	return (
		<div className="page-section slideshow">
			<Swiper
				loop
				modules={[Navigation]}
				navigation={{
					prevEl: navigationPrevRef.current,
					nextEl: navigationNextRef.current,
				}}
				spaceBetween={0}
				slidesPerView={1}
				onBeforeInit={(swiper) => {
					swiper.params.navigation.prevEl = navigationPrevRef.current;
					swiper.params.navigation.nextEl = navigationNextRef.current;
				}}
				// onSlideChange={() => console.log('slide change')}
				// onSwiper={(swiper) => console.log(swiper)}
			>
				{slides}

				<div className="slideshow__btn slideshow__btn--prev" ref={navigationPrevRef}>
					<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10 1L2 9L10 17" stroke="currentColor" strokeWidth="2"/>
					</svg>
				</div>
				<div className="slideshow__btn slideshow__btn--next" ref={navigationNextRef}>
					<svg width="11" height="18" viewBox="0 0 11 18" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M10 1L2 9L10 17" stroke="currentColor" strokeWidth="2"/>
					</svg>
				</div>

			</Swiper>
		</div>
	);

}

export const Text = ({ data }) => {
	
	return (
		<div className="page-section text l-columns-container--outmost">
			<ScrollVisibilityComponent>
				<div className="text__text" dangerouslySetInnerHTML={{__html: data.text}}></div>
			</ScrollVisibilityComponent>
		</div>
	);

}

export const TextColumns = ({ data }) => {
	
	const columns = data.text_columns_columns.map((column, i) => {
		const markup = column.text;
		return (
			<div key={`text_column_${i}`} className="text-columns__column" dangerouslySetInnerHTML={{__html: markup}} />
		);
	});

	return (
		<div className="page-section text-columns l-columns-container--outmost">
			<ScrollVisibilityComponent>
				<div className="l-columns-container">
					{columns}
				</div>
			</ScrollVisibilityComponent>
		</div>
	);

}

export const TwoColumnsImageText = ({ data }) => {

	const image_left_data = data.two_columns_image_and_text_image_left;
	const text_left = data.two_columns_image_and_text_text_left;
	let image_left = <></>;
	if (image_left_data) {
		image_left = (
			<LazyImageComponent image={image_left_data} sizes="(min-width: 700px) 50vw, 100vw" />
		);
	}

	const image_right_data = data.two_columns_image_and_text_image_right;
	const text_right = data.two_columns_image_and_text_text_right;
	let image_right = <></>;
	if (image_right_data) {
		image_right = (
			<LazyImageComponent image={image_right_data} sizes="(min-width: 700px) 50vw, 100vw" />
		);
	}
	return (
		<div className="page-section">
			<ScrollVisibilityComponent>
				<div className={`two-columns-image-text l-columns-container l-columns-container--outmost`}>

					<div className="two-columns-image-text__column two-columns-image-text__column--left">
						{image_left}
						<div className="two-columns-image-text__text" dangerouslySetInnerHTML={{__html: text_left}} />
					</div>

					<div className="two-columns-image-text__column two-columns-image-text__column--right">
						{image_right}
						<div className="two-columns-image-text__text" dangerouslySetInnerHTML={{__html: text_right}} />
					</div>
					
				</div>
			</ScrollVisibilityComponent>
		</div>
	);

}

export const TwoImages = ({ data }) => {

	const layout = data.two_images_layout;

	const image_left_data = data.two_images_image_left;
	let image_left = <></>;
	if (image_left_data) {
		image_left = (
			<div className="two-images__left">
				<LazyImageComponent image={image_left_data} sizes="(min-width: 700px) 50vw, 100vw" />
			</div>
		);
	}

	const image_right_data = data.two_images_image_right;
	let image_right = <></>;
	if (image_right_data) {
		image_right = (
			<div className="two-images__right">
				<LazyImageComponent image={image_right_data} sizes="(min-width: 700px) 50vw, 100vw" />
			</div>
		);
	}

	let title_text = <></>;
	if ( data.text_title || data.text_link ) {
		title_text = <TitleText title={data.text_title} link={data.text_link} color={data.text_text_color} />;
	}

	return (
		<>
			<ScrollVisibilityComponent>
				<div className="page-section">
						<div className="page-section__inner">
							<div className={`two-images two-images--${layout} l-columns-container l-columns-container--outmost`}>
								{image_left}
								{image_right}
							</div>
						</div>
				</div>
			</ScrollVisibilityComponent>
			{title_text}
		</>
	);

}

export const Video = ({ data, key }) => {

	const id = data.vimeo_id ? data.vimeo_id : data.video_vimeo_id;
	
	let title_text = <></>;
	if ( data.text_title || data.text_link ) {
		title_text = <TitleText title={data.text_title} link={data.text_link} color={data.text_text_color} />;
	}

	return (
		<>
			<ScrollVisibilityComponent>
				<div className="page-section video">
					<div className="page-section__inner l-columns-container l-columns-container--outmost">
						<div className="media">
							<iframe title={id} id={id} src={`https://player.vimeo.com/video/${id}?autoplay=1&loop=1&autopause=0&muted=1&${store.getState().Device.isMobile ? 'controls=false' : ''}`} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
						</div>
					</div>
				</div>
			</ScrollVisibilityComponent>
			{title_text}
		</>
	);

}
