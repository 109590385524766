import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux'
// import Sections from '../Sections';

import './index.scss';

import Footer from './../Footer'
import { ImageLarge, ImageFullscreen, Text, TwoImages, Video, ImageAndText } from './projectSections.js'
import { RelatedProjects } from './relatedProjects.js'
// import { CloseSidebar, ToggleSidebar } from './../../actions'
import { ToggleSidebar } from './../../actions'
import LinkWrapper from '../LinkWrapper';
import Sidebar from '../Sidebar';

function ProjectSingle(props) {
    // const [transformAmount, setTransformAmount] = useState(0);
    // const [marginBottomAmount, setMarginBottomAmount] = useState(0);
    const relatedProjectsRef = useRef();
    const projectTitleRef = useRef();
    const projectDataRef = useRef();

    // close sidebar if entering by link, keep filter state if entering by browser history
    // useEffect(() => {
    //     if (props.routerAction === 'PUSH') CloseSidebar();

    //     var data_val = projectDataRef.current.offsetHeight + 80 - (projectDataRef.current.offsetHeight - projectTitleRef.current.offsetHeight);
    //     setMarginBottomAmount(data_val);

    //     var title_val = projectTitleRef.current.offsetHeight + 80;
    //     setTransformAmount(title_val);
    // }, [props.size, props.routerAction])

    // show fixed project title on scroll
    useEffect(() => {
        const scrollTriggerOffset = 400;
        if (props.scrollPosition >= scrollTriggerOffset && props.currentSingleName.length === 0) props.dispatch({ type: 'SHOW_SINGLE_NAME' });
        else if (props.scrollPosition < scrollTriggerOffset && props.currentSingleName.length > 0) props.dispatch({ type: 'HIDE_SINGLE_NAME' })
    }, [props, props.scrollPosition])

    // useEffect(() => {
    //     if (relatedProjectsRef.current) {
    //         let offset = relatedProjectsRef.current.offsetTop
    //         let headlineHeight = projectTitleRef.current.offsetHeight;
    //     }
    // }, [props.sidebarIsOpen])

    let heroImage = <></>;
    let heroModifier = '';
    if (props.apiData && props.apiData.thumb) {

        heroModifier = 'project-single__hero--image';
        const image = { image: props.apiData.thumb };
        heroImage = <ImageFullscreen data={image} hero={true} />

    }

    let projectInfoToggle = <></>;
    if (props.apiData.more_info_sections) {
        projectInfoToggle = (
            <LinkWrapper className={` project-single__info__toggle ${props.filterIsOpen ? ' open' : ''}`}>
                <div className='btn' onClick={() => ToggleSidebar()}>
                    <p>{props.lang === 'en' ? "More info" : "Mer informasjon"}</p>
                    <div className='btn__plus' />
                </div>
            </LinkWrapper>
        );
    }

    let projectData = <></>;
    if (props.apiData.data) {

        const projectDataItems = props.apiData.data.map((item, index) => {
            return (
                <div className="project-single__data__row" key={`data_${index}`}>
                    <div className="title">{item.title}</div>
                    <div className="description">{item.name}</div>
                </div>
            );
        });

        projectData = (
            <>
                <p className="project-single__data__title">{props.lang === 'en' ? "Facts" : "Fakta"}</p>
                <div className="project-single__data__rows">
                    {projectDataItems}
                </div>
            </>
        );
    }

    return (
        <div className={`page project-single${props.sidebarIsOpen ? ' open' : ''}`}>

            <div className={`project-single__hero ${heroModifier}`}>
                {heroImage}
                <div className="project-single__title-container l-columns-container--outmost">
                    <h1 className="project-single__title" ref={projectTitleRef} dangerouslySetInnerHTML={{ __html: props.apiData.alternative_title ? props.apiData.alternative_title : props.apiData.title }} />
                </div>
            </div>

            {/* <div className="page-section l-columns-container--outmost" style={{marginBottom: props.sidebarIsOpen && !props.isMobileSize ? marginBottomAmount : 0}}> */}

            {/* <div ref={projectDataRef} style={{transform: `translate(${props.sidebarIsOpen && !props.isMobileSize ? `-100%, ${transformAmount}px` : '0,0'})`}}> */}
            <div ref={projectDataRef} className="project-single__data l-columns-container--outmost">

                {projectInfoToggle}
                {projectData}

            </div>


            {props.apiData.main_sections ? props.apiData.main_sections.map((singleData, key) => (
                singleData.acf_fc_layout === 'image_fullscreen' ? <ImageFullscreen key={key} data={singleData} /> :
                    singleData.acf_fc_layout === 'image_large' ? <ImageLarge key={key} data={singleData} /> :
                        singleData.acf_fc_layout === 'two_images' ? <TwoImages key={key} data={singleData} /> :
                            singleData.acf_fc_layout === 'text' ? <Text key={key} data={singleData} /> :
                                singleData.acf_fc_layout === 'video' ? <Video key={key} data={singleData} vid={'v' + key} /> :
                                    singleData.acf_fc_layout === 'text_and_image' ? <ImageAndText key={key} data={singleData} /> : null

            )) : null}

            <div className="project-single__related-container" ref={relatedProjectsRef}>
                <RelatedProjects data={props.apiData.related_projects} lang={props.lang} />
            </div>

            <Sidebar dataKey={props.dataKey} />

            <Footer />

        </div>
    );
}

export default connect((state, ownProps) => ({
    apiData: state.App.pageData[ownProps.dataKey].data,
    dataKey: ownProps.dataKey,
    lang: state.App.lang,
    scrollPosition: state.App.scrollPosition,
    sidebarIsOpen: state.Sidebar.sidebarIsOpen,
    currentSingleName: state.App.currentSingleName,
    routerAction: state.router.action,
    isMobileSize: state.Device.isMobileSize,
    size: state.Device.size
}))(ProjectSingle)
