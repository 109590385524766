import React from 'react';
import {connect} from 'react-redux'
import './index.scss';

function Loader(props) {
  return (
    <div id="loader" className={`${props.pageIsLoading ? ' loading' : ''}`}/>
  );
}

export default connect((state) => ({
	pageIsLoading: state.App.pageIsLoading
}))(Loader);
