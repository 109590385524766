export const urbanismData = {
  size: {
    portrait: 0.8,
    landscape: 0.9
  },
  segments: [
  {
    "point": {
      "x": 184.52083720930239,
      "y": 625.2668384069625
    },
    "handleIn": {
      "x": 0,
      "y": 0
    },
    "handleOut": {
      "x": 43.87423255813954,
      "y": -13.660279069767398
    }
  },
  {
    "point": {
      "x": 325.937023255814,
      "y": 556.2480477092881
    },
    "handleIn": {
      "x": -40.69506976744185,
      "y": 31.598139534883785
    },
    "handleOut": {
      "x": 32.958511627907,
      "y": -25.591069767441923
    }
  },
  {
    "point": {
      "x": 383.3048372093024,
      "y": 430.28711747673
    },
    "handleIn": {
      "x": 35.173209302325574,
      "y": 28.33860465116277
    },
    "handleOut": {
      "x": -15.5773023255814,
      "y": -12.549953488372068
    }
  },
  {
    "point": {
      "x": 314.9349767441861,
      "y": 459.021629104637
    },
    "handleIn": {
      "x": 21.486139534883762,
      "y": -28.073674418604696
    },
    "handleOut": {
      "x": -17.050790697674415,
      "y": 22.277953488372077
    }
  },
  {
    "point": {
      "x": 286.66781395348846,
      "y": 535.5566988720789
    },
    "handleIn": {
      "x": 3.2297674418604174,
      "y": -26.097116279069837
    },
    "handleOut": {
      "x": -2.7802790697674595,
      "y": 22.453581395348806
    }
  },
  {
    "point": {
      "x": 292.4754418604652,
      "y": 597.7260011976601
    },
    "handleIn": {
      "x": -8.162232558139578,
      "y": -17.10437209302313
    },
    "handleOut": {
      "x": 19.01544186046516,
      "y": 39.83776744186059
    }
  },
  {
    "point": {
      "x": 401.2099534883722,
      "y": 619.5157686395206
    },
    "handleIn": {
      "x": -45.4548837209303,
      "y": 15.842232558139585
    },
    "handleOut": {
      "x": 90.32334883720927,
      "y": -31.482046511627914
    }
  },
  {
    "point": {
      "x": 652.1226976744188,
      "y": 386.72841980231135
    },
    "handleIn": {
      "x": -22.194604651162877,
      "y": 123.85041860465117
    },
    "handleOut": {
      "x": 6.542883720930149,
      "y": -36.527627906976704
    }
  },
  {
    "point": {
      "x": 612.0854883720931,
      "y": 320.4125128255672
    },
    "handleIn": {
      "x": 25.233860465116322,
      "y": 3.11367441860466
    },
    "handleOut": {
      "x": -50.55106976744196,
      "y": -6.2333023255814055
    }
  },
  {
    "point": {
      "x": 473.82762790697683,
      "y": 419.66014073254394
    },
    "handleIn": {
      "x": 34.860651162790646,
      "y": -58.13283720930235
    },
    "handleOut": {
      "x": -34.86362790697672,
      "y": 58.13283720930235
    }
  },
  {
    "point": {
      "x": 464.12046511627915,
      "y": 593.0316756162648
    },
    "handleIn": {
      "x": -29.273302325581426,
      "y": -41.72204651162781
    },
    "handleOut": {
      "x": 22.620279069767435,
      "y": 32.23813953488377
    }
  },
  {
    "point": {
      "x": 583.020558139535,
      "y": 631.9317686395207
    },
    "handleIn": {
      "x": -41.341023255813866,
      "y": 4.471069767441804
    },
    "handleOut": {
      "x": 41.34102325581398,
      "y": -4.471069767441804
    }
  },
  {
    "point": {
      "x": 707.8235348837209,
      "y": 580.6752104999857
    },
    "handleIn": {
      "x": -40.38251162790675,
      "y": 26.585302325581438
    },
    "handleOut": {
      "x": 60.424930232558154,
      "y": -39.781209302325465
    }
  },
  {
    "point": {
      "x": 867.4484651162791,
      "y": 420.1513035232416
    },
    "handleIn": {
      "x": -42.81748837209307,
      "y": 65.58958139534877
    },
    "handleOut": {
      "x": 17.431813953488472,
      "y": -26.704372093023267
    }
  },
  {
    "point": {
      "x": 907.7357209302324,
      "y": 334.68302445347416
    },
    "handleIn": {
      "x": -7.465674418604522,
      "y": 29.824000000000012
    },
    "handleOut": {
      "x": 7.465674418604749,
      "y": -29.82102325581394
    }
  },
  {
    "point": {
      "x": 899.9039069767441,
      "y": 254.54609422091602
    },
    "handleIn": {
      "x": 13.987720930232626,
      "y": 19.423255813953517
    },
    "handleOut": {
      "x": -20.340093023255804,
      "y": -28.237395348837197
    }
  },
  {
    "point": {
      "x": 800.2782325581395,
      "y": 252.8999546860323
    },
    "handleIn": {
      "x": 35.61972093023269,
      "y": -24.02530232558138
    },
    "handleOut": {
      "x": -35.62269767441853,
      "y": 24.02530232558138
    }
  },
  {
    "point": {
      "x": 718.1171162790697,
      "y": 360.2264663139393
    },
    "handleIn": {
      "x": 18.68502325581403,
      "y": -42.123906976744195
    },
    "handleOut": {
      "x": -12.282046511627868,
      "y": 27.695627906976767
    }
  },
  {
    "point": {
      "x": 694.8538604651162,
      "y": 444.96841980231136
    },
    "handleIn": {
      "x": 1.9170232558140015,
      "y": -27.898046511627854
    },
    "handleOut": {
      "x": -1.925953488371988,
      "y": 28.055813953488382
    }
  },
  {
    "point": {
      "x": 706.0136744186048,
      "y": 522.3518616627765
    },
    "handleIn": {
      "x": -7.742511627907106,
      "y": -23.587720930232535
    },
    "handleOut": {
      "x": 9.570232558139423,
      "y": 29.157209302325555
    }
  },
  {
    "point": {
      "x": 750.6201860465117,
      "y": 595.7970709651021
    },
    "handleIn": {
      "x": -21.378976744186048,
      "y": -17.25618604651163
    },
    "handleOut": {
      "x": 42.35906976744195,
      "y": 34.190883720930174
    }
  },
  {
    "point": {
      "x": 919.3003720930233,
      "y": 586.7447918953346
    },
    "handleIn": {
      "x": -59.073488372092925,
      "y": 33.405023255813944
    },
    "handleOut": {
      "x": 59.076465116278996,
      "y": -33.40204651162787
    }
  },
  {
    "point": {
      "x": 1080.4791627906977,
      "y": 444.7392104999858
    },
    "handleIn": {
      "x": -49.494325581395515,
      "y": 54.80483720930226
    },
    "handleOut": {
      "x": 0,
      "y": 0
    }
  }
]}